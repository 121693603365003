import request from "@/utils/http";

//RM看板
export function getStaffCircleNum(data) {
  return request.post("/rmPerson/headcount", data);
}
//RM看板-人员状态汇总
export function getStaffSummaryNum(data) {
  return request.post("/rmPerson/headcountsummary", data);
}
//org3.2 org4.2查询
export function getOrgThree_FourList() {
  return request.get("/rmPerson/items");
}
//org2 org5.1 org3.2 org4.2查询
export function getOrgTwo_Three_Four_FiveList() {
  return request.post("/organizations/orgItems");
}
//org2 org5.1 org3.2 org4.2联动
export function getOrgLink(data) {
  return request.post("/organizations/orgLinkage",data);
}
//org3.2 org4.2查询 技能岗位审核新接口
export function getOrgThree_Four_RoleMaintainList() {
  return request.get("/rmPerson/reviewer/orgs");
}
//查询staff
export function getRmPersonList(data, params) {
  return request.post("/rmPerson", data, { params });
}

//通过org3.2查询org4.2
export function getOrgFour(params) {
  return request.get(`/rmPerson/condition`, { params });
}

//通过org3.2查询org4.2 技能岗位审核新接口
export function getRoleMaintainOrgFour(params) {
  return request.get(`/rmPerson/reviewer/select`, { params });
}

//批量下载简历
export function downLoadAllResume(params) {
  return request.post(`/staffInfos/downloadCVs`,params);
}

//查询org4.2
export function getOrgFourTwo(params) {
  return request.get(`/rmPerson/items`, { params });
}

//导出人员列表
export function downloadStaffInfo(data) {
  return request.post("/rmPerson/download", data, {
    responseType: "blob",
  });
}

//capability管理人数信息
export function getPersonNum(data) {
  return request.post(`/capabilities/statistics`, data);
}

// 统计Role和简历情况
export function getRoleAndCv(data) {
  return request.post(`/capabilities/roles`,data);
}
//Capability 图表
export function getCapabilityChartCollect(data) {
  return request.post(`/capabilities/collect`,data);
}
//Capability skill下拉框
export function getCapabilitieSkills(params) {
  return request.get(`/staffDictionary/skillsDeptCategories`, { params });
}
//获取人员管理org关系
export function getAllOrgRelatedList(params) {
  return request.get(`/capabilities/orgs`,{ params });
}
//Capability skill下拉框（与部门联动）
export function getDeptRelativeSkills(data) {
  return request.post(`/capabilities/comboboxes`,data, {loading: false});
}
//简历更新确定
export function checkCvReminderTime(data) {
  return request.post(`/rmPerson/checkCvReminderTime`,data);
}
//简历更新发送邮件
export function sendCvReminderToConsultant(data) {
  return request.post(`/rmPerson/sendCvReminderToConsultant`,data);
}

//
//更新note1,note2,note3
export function updateNote(data) {
  return request.post("/rmPerson/updateNote",data);
}

//pm-org3.2 org4.2,account查询
export function queryComboBox() {
  return request.get("/todolists/queryComboBox");
}
//pm-org3.2 org4.2 关系
export function queryComboBoxRelation() {
  return request.get("/todolists/queryNowComboBox");
}
