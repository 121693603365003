<template>
  <div ref="modalDialog">
    <a-modal
        :visible="modelValue"
        :title="title"
        :closable="closable"
        :getContainer="()=>$refs.modalDialog"
        :width="modalWidth"
        @cancel="handleCancel"
        @ok="handleOk"
        :cancelText="cancelText"
        :okText="okText"
        centered
        :z-index="10002"
    >
      <slot name="content"></slot>
      <p :style="showIconCom?'padding-left: 24px':''"><component :is="showIconCom" />{{content}}</p>
      <template #footer v-if="footer">
        <a-button type="primary" @click.stop="()=>{handleCancel()}">{{ okText }}</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from "vue";
//黑白圆圈 ! X     双色风格圆圈  !
import {ExclamationCircleOutlined, CloseCircleOutlined,ExclamationCircleTwoTone} from '@ant-design/icons-vue'
export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '提示'
    },
    showIconCom: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    closable: {
      type: Boolean,
      default: true
    },
    modalWidth: {
      type: Number || String,
      default: 400
    },
    cancel: {
      type: Function,
      default: ()=> {}
    },
    ok: {
      type: Function,
      default: ()=> {}
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    okText: {
      type: String,
      default: '确定'
    },
    okButtonProps: {
      type: Object,
      default: ()=>{}
    },
    footer: {
      type: Boolean,
      default: false
    }
  },
  components: {ExclamationCircleOutlined,CloseCircleOutlined,ExclamationCircleTwoTone},
  emits:['update:modelValue'],
  setup(props, {emit}) {
    const handleOk = () => {
      if(props.ok){
        props.ok()
      }
      close()
    }
    const handleCancel = () => {
      if(props.cancel){
        props.cancel()
      }
      close()
    }
    const close = () => {
      emit("update:modelValue", false);
    }
    return {
      handleOk,
      handleCancel
    }
  }
})

</script>
<style lang="less" scoped>
@import "~@/style/aModal.less";
:deep(.ant-modal-body) {
  max-height: 300px;
  overflow: auto;
  font-size: 16px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 13px;
    background: #d0e8ff;
    border: 1px solid #000;
    border-radius: 5px;
  }
  p{
    position: relative;
    line-height: 24px;
  }
  .anticon {
    position: absolute;
    top: 2px;
    left: 0;
    margin-right: 8px;
    font-size: 20px;
  }
  .anticon-exclamation-circle {
    color: #fdcd46;
  }
  .anticon-close-circle {
    color: #DC5E4F;
  }
}

</style>
