import store from "@/store";

function checkPermission(el, binding) {
  const { value } = binding;
  let roles = store.state && store.state.app.perms;

  if (value && value instanceof Array) {
    if (value.length > 0) {
      const permissionRoles = value;
      if(!roles){
        roles = []
      }
      const hasPermission = roles.some((role) => {
        return permissionRoles.includes(role);
      });
      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    }
  } else {
    throw new Error(`need roles! Like v-auth="['admin','editor']"`);
  }
}

export default {
  mounted(el, binding) {
    checkPermission(el, binding);
  },
  update(el, binding) {
    checkPermission(el, binding);
  },
};
