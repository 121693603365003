import request from "@/utils/http";

// 查询RM可管理人员列表
export function getGTEDataList(data, params) {
  return request.post(`/arveMaintain/consultants`, data, { params });
}

// 计算FCST和GAP并保存
export function calculateFCST(params) {
  return request.post(`/calculate/FCST`, {}, { params });
}

// 修改BT,计算Operation ARVE和GAP
export function calculateBTAV(params) {
  return request.get(`/calculate/BTAV`, { params });
}

// 保存arve
export function saveGTEData(data, params) {
  return request.post(`/arveMaintain/save`, data, { params });
}

// 批量计算当前RM下人员的BT FCST、BT FCST vs Actual BT GAP
export function calculateAll() {
  return request.get(`/calculate/all`);
}

// 根据权限查询ActualARVE汇总树
export function getMonthTree(data) {
  return request.post(`/TeamARVE/report`, data);
}
// 根据权限查询ActualARVE汇总树
export function getYearTree(data) {
  return request.post(`/report/year`, data);
}

// 根据权限查询ActualARVE汇总树
export function teamReportExport(data) {
  return request.post("/TeamARVE/export", data, {
    responseType: "blob",
  });
}

// 导出GTE数据
export function gteDataExport(data) {
  return request.post("/arveMaintain/export", data, {
    responseType: "blob",
  });
}

// 获取个人目标列表
export function getPersonalTargetReport(data) {
  return request.post("/target/page", data);
}

// 导出个人目标列表
export function exportPersonalTargetReport(data) {
  return request.post("/target/download", data, {
    responseType: "blob",
  });
}

// 导出个人目标excel模板
export function exportPersonalTargetReportTemplate() {
  return request.get("/target/template", {
    responseType: "blob",
  });
}

// 编辑个人目标
export function updatePersonalTargetReport(data) {
  return request.post("/target/edit", data);
}

// 导入个人目标
export function importPersonalTargetReport(data) {
  return request.post("/target/import", data);
}

// 获取团队目标
export function getOrgTargetReport(data) {
  return request.post("/orgTarget/list", data);
}

// 导出团队目标列表
export function exportOrgTargetReport(data) {
  return request.post("/orgTarget/download", data, {
    responseType: "blob",
  });
}

// 导入团队目标
export function importOrgTargetReport(data) {
  return request.post("/orgTarget/import", data);
}

// 导出团队目标模板
export function exportOrgTargetReportTemplate(data) {
  return request.post("/orgTarget/template", data, {
    responseType: "blob",
  });
}

// 个人报表列表
export function getPersonalReportList (data) {
  return request.post("/report/person", data);
}

// 个人报表导出
export function personalReportExport(data) {
  return request.post("/report/export", data, {
    responseType: "blob",
  });
}

// 个人报表列表
export function getArveTips () {
  return request.get("/arveMaintain/arveTips");
}

//FCST列表
export function getFCSTReportList (data) {
  return request.post("/FCST/report", data);
}

//FCST列表自定义列表
export function queryFCSTSort(flag) {
  return request.get(`/customSort/FCSTHeaderSort?isDefault=${flag}`);
}

// FCST导出
export function fcstReportExport(data) {
  return request.post("/FCST/export", data);
}

///FCST查询计算系数
export function queryCoefficient(data) {
  return request.get("/FCST/queryCoefficient", data);
}

///FCST保存计算系数
export function saveCoefficient(data){
  return request.post("/FCST/saveCoefficient",data)
}
