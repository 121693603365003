import request from "@/utils/http";

// 下载任务列表
export function getDownloadTask(data) {
    return request.post(`/staffFile/getDownloadTask`,data);
}

// 下载任务-下载
export function downloadCVs(relationId) {
    return request.get(`/staffFile/downloadCVs?relationId=${relationId}`,{ responseType: "blob" });
}
// 下载任务-下载任务类型下拉框
export function getTaskTypeSelect() {
    return request.get(`/staffFile/getTaskTypeSelect`);
}
// 上传任务列表
export function getUploadTask(data) {
    return request.post(`/staffFile/getUploadTask`,data);
}
// 文件中转站未读消息
export function queryMark() {
    return request.get("/staffFile/queryMark", { loading: false });
}
// 上传任务异常信息
export function getErrorMsg(fileTaskId,pageIndex,pageSize) {
    return request.get(`/staffFile/getErrorMsg?fileTaskId=${fileTaskId}&pageIndex=${pageIndex}&pageSize=${pageSize}`);
}
