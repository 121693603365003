import request from "@/utils/http";

// 查询Organization信息
export function getOrganizationList(params) {
  return request.get(`/organizations`, { params });
}

// 保存Organization信息
export function saveOrganization(params) {
  return request.post(`/organizations/save`, params);
}

// 停用Organization信息
export function deactivateOrganization(params) {
  return request.post(`/organizations/logicalDeletion`, {}, { params });
}

// 启用Organization信息
export function enableOrganization(params) {
  return request.post(`/organizations/logicalEnable`, {}, { params });
}

//获取CST Leader下拉框数据
export function queryCstLeader(data) {
  return request.post(`/organizations/queryCstLeader`, data );
}

//批量启用Organization信息
export function batchLogicalEnable(params) {
  return request.post(`/organizations/batchLogicalEnable`,   params );
}

// 批量停用Organization信息
export function batchLogicalDeletion(params) {
  return request.post(`/organizations/batchLogicalDeletion`,   params );
}

//查询Organization状态信息
export function queryNowStatus(params) {
  return request.get(`/organizations/queryNowStatus`, { params });
}
