import request from "@/utils/http";

export function addMenuPerm(data) {
    return request.post('/staffPerms/new/addMenuPerm', data);
}

export function addBtnPerm(data) {
    return request.post('/staffPerms/new/addBtnPerm', data);
}

export function addPathPerm(data) {
    return request.post('/staffPerms/new/addPathPerm', data);
}

export function permInfosForBackend(name) {
    return request.get(`/staffPerms/getPermInfosForBackend?name=${name}`);
}
