import request from "@/utils/http";

// dashboard 待办事项
export function todoListinfo() {
  return request.post(`/todolists/todoListinfo`);
}
// dashboard welcomeletter 饼图
export function queryWelcomeLetterNum(params) {
  return request.post(`/todolists/queryWelcomeLetterNum`,params);
}
// dashboard 需求匹配 饼图
export function queryPrematchingsNum(params) {
  return request.post(`/todolists/queryPrematchingsNum`,params);
}
// dashboard 调令管理 饼图
export function queryAssignmentsNum(params) {
  return request.post(`/todolists/queryAssignmentsNum`,params);
}
// dashboard 获取当前角色菜单列表
export function menuPerms() {
  return request.get(`/menus/perms`);
}
// dashboard 查询当前角色快捷入口顺序
export function getQueryQuickEntry(flag) {
  return request.get(`/customSort/queryQuickEntry?isDefault=${flag}`);
}
// dashboard 保存当前角色快捷入口
export function saveQetQueryQuickEntry(params) {
  return request.post(`/customSort/saveQuickEntry`,params);
}
