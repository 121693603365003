import request from "@/utils/http";

// 查询待办列表assignments
// export function getTodoAssignmentList(params) {
//   return request.get(`/todolists/assignments`, { params });
// }

// 查询待办列表preMatchings
export function getTodoPreMatchingList(params) {
  return request.get(`/todolists/prematchings`, { params });
}

// 查询待办列表preMatchings
export function getNewTodoAssignmentList(listUrl, params) {
  return request.post(listUrl, params);
}

// 获取待办数量
export function getTodoNum() {
  return request.get("/todolists/allNumbers", { loading: false });
}

// 获取待办数量
export function getTodoAccount() {
    return request.get("/todolists/accounts", { loading: false });
}

// 导出调令待办
export function doAssExport(params) {
  return request.post(
      "/todolists/assignments/export",
      params,
      {
        responseType: "blob",
      }
  );
}
// 导出匹配待办
export function doMarchingExport(params) {
  return request.post(
      "/todolists/prematchings/export",
      {},
      {
        params,
        responseType: "blob",
      }
  );
}
