import request from "@/utils/http";

// DataPermByCostCtr 维护查询
export function getDataPermByCostCtrList(data) {
  return request.post("/dataperms/bycostctr", data );
}

// 查询cost ctr Person
export function getCtrPersonList(params) {
  return request.get("/dataperms/detail", { params });
}

// DataPermByCostCtr 维护
export function savePerson(data) {
  return request.post("/dataperms/save", data);
}

// 人员维度:查询数据权限
export function getDataPermByPersonList(params) {
  return request.get("/dataperms/byperson", { params });
}

// 人员维度:根据人员和角色查询costCtr信息
export function getCostCtrByPersonAndRoleList(params) {
  return request.get("/dataperms/costctrs", { params });
}

// 人员维度:维护人员某个角色所属costCtr
export function savePerms(data) {
  return request.post("/dataperms/perms", data);
}

// 获取costcenter下拉框信息
export function getAllCostCtrList(params) {
  return request.get("/dataperms/costcenters", { params });
}

//人员维度:复制人员角色权限
export function copyDataPerm(data) {
  return request.post("/dataperms/copy", data);
}

//查询当前costCtr没有的角色
export function getNotHaveRoles(params) {
  return request.get("/dataperms/notHave", { params });
}
//导入
export function dataPermsImport(data) {
  return request.post("/dataperms/import", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

//下载模板
export function dataPermsDownload() {
  return request.get("/dataperms/downloadTemplate", {
    responseType: "blob",
  });
}

//导出
export function dataPermsExport(data) {
  return request.post("/dataperms/export", data, {
    responseType: "blob",
  });
}

//校验互斥
export function checkRoleMutex(data) {
  return request.post("/dataperms/checkRoleMutex", data);
}
