<template>
  <div style="height: 100%">
    <a-popover :overlayClassName="value && value.length > 0 ? '' : 'select-all-popover-hidden'">
      <template v-slot:content>
        <div class="select-all-popover-content">
          {{ popoverText }}
        </div>
      </template>
      <a-select
          class="select-all-box"
          @inputKeyDown="(e) => {e.stopPropagation()}"
          style="width:100%"
          :dropdownClassName="'select-all-dropdownlist' + uid"
          v-bind="{ ...$attrs, maxTagCount: tagCount > 1? tagCount : 1 , value: valueRef}"
          show-search
          mode="multiple"
          showArrow
          :filterOption="filterOption"
          @change="e => { $emit('update:value',e); $emit('change') }">
        <slot></slot>
        <template #dropdownRender="{ menuNode: menu }">
          <v-nodes :vnodes="menu"/>
          <a-divider style="margin: 4px 0"/>
          <div class="select-all-btn-wrap">
            <div class="select-all" @click="(e) => e.stopPropagation()">
              <a-button type="primary" size="small" style="font-size:12px;height:20px;" @click="selectAll">全选</a-button>
              <a-button size="small" style="margin-left:5px;font-size:12px;height:20px;"  @click="selectAll(false)">取消</a-button>
            </div>
          </div>
        </template>
      </a-select>
    </a-popover>
  </div>
</template>

<script>
import {ref, watch, getCurrentInstance, computed} from "vue";

export default {
  components: {
    VNodes: (_, {attrs}) => {
      return attrs.vnodes;
    },
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    tagCount:{
      type: Number,
      default: 1,
    },
  },
  emits: ["update:value", "change"],
  setup(props, context) {
    const popoverText = ref("");
    const valueRef = ref(props.value);
    const selectAll = (bool) => {
      valueRef.value = [];
      if (bool === false) {
        context.emit("update:value", valueRef.value);
        context.emit("change");
        return;
      }
      if (context.slots.default) {
        const list = context.slots.default()[0].children;
        for (let i = 0; i < list.length; i++) {
          valueRef.value.push(list[i].props.value);
        }
        context.emit("update:value", valueRef.value);
        context.emit("change");
      } else if (context.attrs.options.length > 0) {
        const optList = context.attrs.options;
        for (let l = 0; l < optList.length; l++) {
          valueRef.value.push(optList[l].value);
        }
        context.emit("update:value", valueRef.value);
        context.emit("change");
      }
    };
    const { proxy } = getCurrentInstance();
    const findContent = (vnode) => {
      let str = '';
      if(Array.isArray(vnode)) {
        vnode.forEach(item => {
          str += findContent(item);
        })
      } else if(typeof vnode === 'string') {
        str += vnode;
      } else if (vnode?.children) {
        str += findContent(vnode.children)
      } else {
        console.error('未知的子vnode类型');
        return '-';
      }
      return str
    }

    const propsAndOptions = computed(() => {
      return {
        propsVal: props.value,
        optVal: context.attrs.options,
        slotOptVal: context.slots.default ? context.slots.default()[0] : undefined,
      }
    })
    const filterOption = (input, option) => {
      if (!input) return false;
      let value
      if(option.label){
        value = option.value ?
            (option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0 || option.label.toUpperCase().indexOf(input.toUpperCase()) >= 0)
            : ''
      } else {
        value = option.value ? option.value.toUpperCase().indexOf(input.toUpperCase()) >= 0 : ''
      }
      return value
    };
    watch(
        () => propsAndOptions.value,
        (val) => {
          valueRef.value = val.propsVal
          // 处理气泡显示所选项
          popoverText.value = null;
          const popoverTextArr = [];
          const map = {}
          if(val.propsVal?.length) {
            val.propsVal.forEach(item=>{
              map[item] = true
            })
            if (context.slots.default) {  // 针对通过slot传入
              const children = val.slotOptVal
              const list = children.children
              for(let i = 0; i < list.length; i++) {
                if(map[list[i].props.value]) {
                  if(list[i]?.children.default) {
                    const vnode = list[i].children.default()
                    popoverTextArr.push(findContent(vnode));
                  }
                }
              }
              popoverText.value = popoverTextArr.join(', ')
            } else if (val.optVal.length > 0) { // 针对通过options传入
              const optList = val.optVal;
              for (let l = 0; l < optList.length; l++) {
                if(map[optList[l].value]){
                  popoverTextArr.push(optList[l].label);
                }
              }
              popoverText.value = popoverTextArr.join(', ');
            }
          }

          // 解决撑开选择框导致的高度变化 start
          proxy.$EventBus.emit('change-select-height');
          // end
        },
        {deep: true, immediate: true,}
    )
    return {
      valueRef,
      selectAll,
      popoverText,
      uid: proxy._.uid,
      propsAndOptions,
      filterOption
    };
  },
};
</script>

<style lang="less" scoped>
.select-all-btn-wrap {
  .select-all {
    padding: 0.2vw 0.5vw;
  }

  :deep(.ant-btn-sm){
    font-size: 12px;
  }
}
.select-all-box{
  height: 100%;
  :deep(.ant-select-selector){
    height: 100%;
  }
}
:deep(.ant-select-selection-overflow-item){
  max-width: 73%;
}
:deep(.ant-select-selection-overflow) {
  flex-wrap: nowrap;
}
</style>
<style>
.select-all-popover-hidden {
  display: none;
}
.select-all-popover-content{
  max-height: 50vh;
  overflow-y: auto;
}
</style>
