
// 判断当前环境，如生产环境则不展示标签 用当前url的origin判断访问环境，env的配置文件会随着打包均执行env.production文件，不足以用于区分三个环境。
const currentUrl = window.location.origin;
console.log(currentUrl)
export const currentEnv =
    currentUrl === "http://139.217.82.210:8800"
        ? { name: "预生产环境", flag: true }
        : currentUrl === "http://139.217.82.210" || currentUrl === "http://localhost:9028"
            ? { name: "测试环境", flag: true }
            : { name: "", flag: false };
