<template>
  <div id="box">
    <Header class="header" @change-page-list="changePageList"></Header>
    <div class="box">
      <!--      <Menu class="menu"></Menu>-->
      <div class="page">
        <!--        <router-view />-->
        <router-view v-slot="{ Component }">
          <keep-alive :include="KeepAliveList">
            <component :is="Component"></component>
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "./header.vue";
import Menu from "@/layout/menu";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  components: {
    Menu,
    Header,
  },
  setup() {
    const pageList = ref([]);
    const KeepAliveList = computed(() =>
      pageList.value
        .filter((item) => item.keepAlive !== false)
        .map((item) => item.name)
    );
    const changePageList = (list) => {
      pageList.value = list;
    };

    return {
      KeepAliveList,
      changePageList,
    };
  },
});
</script>
<style lang="less" scoped>
#box {
  background: #f7fafc;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  flex-direction: column;

  .header {
    height: 60px !important;
  }

  .box {
    height: calc(100vh - 100px);
    display: flex;
    overflow: auto;
    .page {
      //background: white;
      margin: 0 5px 10px 5px;
      //max-width: 100vw;
      width: calc(100% - 10px);
      height: calc(100% - 10px);
    }
  }
}
</style>
