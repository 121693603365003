import { getPerms, getMenus } from "@/api/modules/Home/userInfo.js";
import { getNoticeNum } from "@/api/modules/Home/notice.js";
import { getTodoNum } from "@/api/modules/Home/todoList"
import { getAnnouncementsNotice } from "@/api/modules/Login/login"
import {queryMark} from "@/api/modules/Home/fileTransferStation";

const app = {
  state: {
    user: {},
    token: "",
    portrait: "",
    perms: "",
    menus: [],
    pages: "",
    spinning: false,
    countNum: 0,
    // 代办列表跳转的状态
    isTodoStatus: false,
    //菜单
    menusList: [],
    // 是否登陆后第一个页面
    isFirst: false,
    // pending状态的请求数量
    pendingRequestCount: 0,
    // 字典
    dictionary: {},
    updatingMsg: "",
    isUpdating: false,
    isLockingSystem: false,
    //默认角色
    defaultRole: "",
    //RMS说明手册
    adminRelation: [],
    //判断文件中转站是否有未读消息
    isFileMessage: false,
  },
  mutations: {
    SET_PENDING_REQUEST_COUNT: (state, payload) => {
      state.pendingRequestCount = payload;
    },
    ADD_PENDING_REQUEST_COUNT: (state) => {
      state.pendingRequestCount++;
    },
    SUBTRACT_PENDING_REQUEST_COUNT: (state) => {
      state.pendingRequestCount--;
    },
    RESET_APP: (state) => {
      const obj = {
        user: {},
        token: "",
        portrait: "",
        perms: "",
        menus: "",
        pages: "",
        spinning: false,
        countNum: 0,
        todoNum: 0,
        // 代办列表跳转的状态
        isTodoStatus: false,
        isUpdating: false,
        updatingMsg: "",
        isLockingSystem: false,
        isFileMessage: false,
      };
      for (let i in obj) {
        state[i] = obj[i];
      }
      localStorage.clear()
    },
    SET_TOKEN: (state, payload) => {
      state.token = payload;
    },
    SET_USERINFO: (state, payload) => {
      state.user = payload;
    },
    SET_PORTIAIT: (state, payload) => {
      state.portrait = payload;
    },
    SET_PERMS: (state, payload) => {
      state.perms = payload;
    },
    SET_MENUS: (state, payload) => {
      state.menus = payload;
    },
    SET_PAGES: (state, payload) => {
      state.pages = payload;
    },
    SET_SPINNING: (state, payload) => {
      state.spinning = payload;
    },
    SET_ISUPDATING: (state, payload) => {
      state.isUpdating = payload;
    },
    SET_ISLOCKINGSYSTEM: (state, payload) => {
      state.isLockingSystem = payload;
    },
    SET_UPDATINGMSG: (state, payload) => {
      state.updatingMsg = payload;
    },
    SET_UPDATASPINNING(state, payload) {
      state.spinning = payload;
    },
    SET_COUNTNUM(state, payload) {
      state.countNum = payload;
    },
    SET_TODONUM(state, payload) {
      state.todoNum = payload;
    },
    RESET_TOKEN: (state) => {
      state.token = "";
      state.user = {};
      state.perms = [];
      state.menus = [];
      state.pages = [];
      localStorage.clear()
    },
    SET_IS_TODO_STATUS: (state, isTodoStatus) => {
      state.isTodoStatus = isTodoStatus;
    },
    SET_MENUSLIST: (state, payload) => {
      // @todo coo暂看不到任务管理菜单
      if(state.user.roles.indexOf("12") !== -1) {
        payload = payload.filter((item)=> {
          return item.title !=="任务管理"
        })
      }
      state.menusList = payload;
    },
    SET_IS_FIRST: (state, isFirst) => {
      state.isFirst = isFirst;
    },
    SET_DICTIONARY: (state, dictionary) => {
      state.dictionary = dictionary;
    },
    SET_DEFAULT_ROLE: (state, payload) => {
      state.defaultRole = payload;
    },
    SET_ADMIN: (state, payload) => {
      state.adminRelation = payload;
    },
    SET_ISFILE(state, payload) {
      state.isFileMessage = payload;
    },
  },
  actions: {
    generatePermission: ({ commit }) => {
      return new Promise((resolve, reject) => {
        getPerms()
          .then((res) => {
            const { btn, menu, router } = res.data;
            commit("SET_PERMS", btn);
            commit("SET_MENUS", menu);
            commit("SET_PAGES", router);
            localStorage.setItem("accessPerms", btn)
            localStorage.setItem("accessMenus", menu)
            localStorage.setItem("accessPages", router)
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getCountNum: ({ commit }) => {
      return new Promise((resolve, reject) => {
        getNoticeNum()
          .then((res) => {
            commit("SET_COUNTNUM", res.data);
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    getTodoAllNum: ({ commit }) => {
      return new Promise((resolve, reject) => {
        getTodoNum()
            .then((res) => {
              commit("SET_TODONUM", res.data);
              resolve(res.data);
            })
            .catch((e) => {
              // message.error("获取待办数量失败")
              reject(e);
            });
      });
    },
    generateMenus: ({ commit }) => {
      return new Promise((resole, reject) => {
        getMenus()
          .then((res) => {
            commit("SET_MENUSLIST", res.data);
            const menus = {menusList: res.data}
            localStorage.setItem("menusList", JSON.stringify(menus))
            resole(res.data);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    updateUpgradeAnnouncement: ({ commit }) => {
      return new Promise((resole, reject) => {
        const tempList = []
        getAnnouncementsNotice()
            .then((res) => {
              commit("SET_ISUPDATING", res.data?.length > 0);
              commit("SET_ISLOCKINGSYSTEM", res.data?.some((item) => item.type == '1'));
              res.data?.forEach(item => {
                tempList.push(item.content)
              })
              commit("SET_UPDATINGMSG", tempList.join(''));
              resole(res.data);
            })
            .catch((e) => {
              reject(e);
            });
      });
    },
    getIsFileMessage: ({ commit }) => {
      return new Promise((resolve, reject) => {
        queryMark()
            .then((res) => {
              commit("SET_ISFILE", res.data);
              resolve(res.data);
            })
            .catch((e) => {
              reject(e);
            });
      });
    },
  },
};
export default app;
