import request from "@/utils/http";

export function getAllByUserCode(data) {
  return request.get(`/certifications`, { params: data });
}

export function isExisted(params) {
  return request.post(`/certifications/exist`, {}, { params });
}

export function addCertification(data) {
  return request.post(`/certifications`, data);
}

export function deleteCertification(id) {
  return request.post(`/certifications/${id}`);
}

export function updateCertification(data) {
  return request.post(`/certifications/update`, data);
}

export function getAllCtf() {
  return request.get(`/certifications/ctf`, { loading: false });
}

export function searchCtf(name) {
  return request.get(`/certifications/ctfs`, name);
}
