import request from "@/utils/http";
// 获取角色详情信息
export function getRoleDetailInfo(params) {
    return request.get("/roles/queryRoleInfo", { params });
}
// 新增/复制/编辑 保存
export function addNewRole(data) {
    return request.post("/roles/saveRole", data);
}
// 校验禁用/删除角色下是否挂有人员
export function checkRoleStaff(params) {
    return request.get("/roles/checkRoleStaff", { params });
}
// 启用/禁用/删除角色
export function updateRole(params) {
    return request.get("/roles/updateRole", { params });
}
// 获取一级菜单
export function getFirstMenuPerms(data) {
    return request.post("/staffPerms/firstMenuPerms", data);
}

// 根据角色和一级菜单 查询拥有的权限列表
export function getCurentPermList(data) {
    return request.post("/staffPerms/permLevelsByRoleAndMenu", data);
}
// 根据角色和一级菜单 查询拥有的权限Id
export function getCurentPermIds(data) {
    return request.post("/roles/permIdsByRoleAndMenu", data);
}
// 根据角色和一级菜单 查询所有权限列表
export function getAllPermList(data) {
    return request.post("/staffPerms/allPermLevelsByMenu", data);
}
//
export function saveRolePerms(data) {
    return request.post("/roles/saveRolePerms", data);
}
