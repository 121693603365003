<template>
  <div id="nav"></div>
  <div id="main-page">
    <router-view />
  </div>
  <teleport to="body">
    <a-spin
      class="body-spin"
      v-if="!isLockingSystem && spinning"
      :spinning="spinning"
      size="large"
    />
    <!-- operator不能被锁定 -->
    <div class="body-spin"
         v-if="!perms.includes('system:header:lockSystemFree')"
         v-show="isLockingSystem"></div>
  </teleport>
</template>

<script>
import { computed, defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const store = useStore();
    const spinning = computed(() => store.state.app.pendingRequestCount > 0);
    const isLockingSystem = computed(() => store.state.app.isLockingSystem);
    const perms = computed(() => store.state.app.perms);
    onMounted(() => {
      if (sessionStorage.getItem("store")) {
        store.replaceState(
          Object.assign(
            {},
            store.state,
            JSON.parse(sessionStorage.getItem("store"))
          )
        );
      }

      window.remainStore = () => {
        store.commit("SET_PENDING_REQUEST_COUNT", 0);
        sessionStorage.setItem("store", JSON.stringify(store.state));
      }

      window.addEventListener("beforeunload", window.remainStore);
    });

    return {
      spinning,
      isLockingSystem,
      perms,
    };
  },
});
</script>
<style lang="less" scoped>
@import "~@/style/aModal.less";
.body-spin {
  max-height: unset !important;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  padding-top: 30vh;
  background: #ffffff66;
  z-index: 10000;
}
#main-page {
  height: 100vh;
}
</style>
