import request from "@/utils/http";

export function getOneUser(userId) {
  return request.get(`/users/userInfo/${userId}`);
}

export function myUserInfo() {
  return request.get(`/users/myUserInfo`);
}

export function getUpload(data) {
  return request.post("/users/upload", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getResumeList(userId) {
  return request.get(`/users/queryResumeList/${userId}`);
}

export function removeResume(id) {
  return request.get(`/users/removeResume/${id}`);
}

export function getUploadPhoto(data) {
  return request.post("/users/uploadPhoto", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function getPhoto(userId) {
  return request.get(`/users/queryPhoto/${userId}`, { loading: false });
}

export function queryMyPhoto() {
  return request.get(`/users/queryMyPhoto`, { loading: false });
}

export function getPerms() {
  return request.get("/system/getPerms");
}

export function getZipCV(staffId) {
  return request.get(`/staffInfos/downloadCV/${staffId}`, {
    responseType: "blob",
  });
}

export function getOneCV(staffId) {
  return request.get(`/users/downloadCV/${staffId}`, {
    responseType: "blob",
  });
}

//获取菜单
export function getMenus() {
  return request.get("/menus/perms");
}

//下载简历模板
export function getResumeTep() {
  return request.get("/users/downloadCVTemplate", { responseType: "blob" });
}

//切换角色时查询角色
export function getRole(params) {
  return request.get("/system/getRole",{params});
}
//修改默认角色
export function updateDefaultRole(params) {
  return request.post("/system/updateDefaultRole",{} ,{params});
}
//切换角色时重新获取菜单
export function getPermsByRole(params) {
  return request.get("/system/getPermsByRole",{params});
}
//切换角色时重新获取按钮、菜单、路径权限
export function permsByRoleId(params) {
  return request.get("/menus/permsByRoleId",{params});
}
//切换角色时重新获取接口权限
export function getPermsByEmail(data) {
  return request.post("/system/getPermsByEmail",data);
}
//RMS说明手册下载
export function downloadManualFile(id) {
  return request.get(`/rmsInstructionManual/download/${id}`, { responseType: "blob" });
}
//查询手册
export function queryManual(params) {
  return request.get("/rmsInstructionManual/queryManual",{params});
}
//预览手册
export function previewManual(id) {
  return request.get(`/rmsInstructionManual/previewManual/${id}`);
}
//更新上传手册
export function updateManual(data) {
  return request.post("/rmsInstructionManual/updateManual", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

//代上传简历
export function postUpload(data, params) {
  return request.post("/users/rmTapeFillingUpload", data, {
    params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

//更新简历
export function postUpdate(data, params) {
  return request.post("/users/updateResume", data, {
    params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// 预览简历
export function reviewCV(id) {
  return request.get(`/users/previewCV/${id}`);
}

// 删除文件
export function delManualFile(fileId) {
  return request.get(`/staffFile/deleteFile/${fileId}`);
}
