import request from "@/utils/http";

//手动通知查询
export function postManualNotifications(data) {
  return request.post(`/manualnotification/list`, data);
}
//公告查询
export function postAnnouncementList(data) {
  return request.post(`/staffannouncement/list`, data);
}
//获取手动通知内容详情页
export function getManualDeatisList(notificationMark) {
  return request.get(`/notificationContent/content?notificationMark=${notificationMark}`);
}
//公告-停用
export function getAnnouncementDeactivate(id) {
  return request.get(`staffannouncement/deactivate?id=${id}`);
}
//手动发布通知-停用   GET/manualnotification/deactivate
export function getmanualnotificationdeactivate(id) {
  return request.get(`/manualnotification/deactivate?id=${id}`);
}
//手动发布通知-下载收件人&抄送人信息
export function staffannouncementlist(data) {
  return request.post(`/manualnotification/download`, data, { responseType: "blob" });
}
//公告-新增/编辑-获取公告主题
export function getManualNotification(params) {
  return request.get(`/manualnotification/getManualNotification`, params);
}
//公告-新增-保存
export function postAnnouncementSave(data) {
  return request.post(`/staffannouncement/save`, data);
}
//公告-编辑-保存
export function postAnnouncementEdit(data) {
  return request.post(`/staffannouncement/updateNotice`, data);
}
//公告-下载
export function postAnnouncementDownload(data) {
  return request.post("/staffannouncement/download", data, { responseType: "blob" });
}
//获取角色和人员下拉列表
export function getRoleInfos(params) {
  return request.get(`/manualnotification/items`,{params});
}
//角色和人员下拉列表筛选关联关系
export function getRoleInfosRelations(params) {
  return request.get(`/manualnotification/relations`,{params});
}
//保存通知内容
export function saveNotificationContent(data) {
  return request.post(`/notificationContent/saveContent`, data);
}
//新建手动通知保存
export function saveHanderNotification(data) {
  return request.post(`/manualnotification/save`, data);
}
//通知内容页的角色下拉列表
export function getRoleOpsList(params) {
  return request.get(`/roles/roleList`,{params});
}
//手动发布通知-查询一、二级菜单下拉列表
export function getMenu(params) {
  return request.get(`/staffPerms/getMenu`,{params});
}
//手动发布通知-查询一、二级菜单下拉列表关联关系
export function getMenuRelations(params) {
  return request.get(`/staffPerms/getMenuRelations`,{params});
}
//编辑-通知内容保存
export function updateContent(data) {
  return request.post(`/notificationContent/updateContent`, data);
}
//编辑-更新手动通知的保存
export function updateSave(data) {
  return request.post(`/manualnotification/update`, data);
}
//编辑-手动通知
export function getEditManual(params) {
  return request.get(`/manualnotification/getManual`,{params});
}
