import { createRouter, createWebHistory } from "vue-router";

import Layout from "@/layout/index.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import("@/views/home/home.vue"),
        meta: {
          name: "首页",
          keepAlive: false,
        },
      },
      {
        path: "/userInfo",
        name: "userInfo",
        component: () => import("@/views/home/UserInfo/UserInfo"),
        meta: {
          name: "个人信息",
        },
      },
      {
        path: "/newMatch",
        name: "newMatch",
        component: () =>
          import("@/views/home/ResourceManagement/Match/newMatch"),
        meta: {
          name: "需求匹配",
        },
      },
      {
        path: "/matchingList",
        name: "matchingList",
        component: () =>
          import("@/views/home/ResourceManagement/Match/matchingList"),
        meta: {
          name: "需求匹配",
        },
      },
      {
        path: "/matchingInfoList",
        name: "matchingInfoList",
        component: () =>
          import("@/views/home/ResourceManagement/Match/matchingInfoList"),
        meta: {
          name: "需求信息",
        },
      },
      {
        path: "/roleauthorization",
        name: "roleAuthorization",
        component: () =>
          import("@/views/home/ResourceManagement/RoleAuthorization"),
        meta: {
          name: "角色授权",
        },
      },
      {
        path: "/consultantMatchingList",
        name: "consultantMatchingList",
        component: () =>
          import(
            "@/views/home/ResourceManagement/Match/ConsultantMatchingList"
          ),
        meta: {
          name: "需求匹配",
        },
      },
      {
        path: "/resource/exportData",
        name: "exportData",
        component: () => import("@/views/home/ResourceManagement/ExportData"),
        meta: {
          name: "数据导出",
        },
      },
      {
        path: "/resource/task",
        name: "resourceTask",
        component: () => import("@/views/home/ResourceManagement/Task"),
        meta: {
          name: "Task管理",
        },
      },
      {
        path: "/benchTask/consultantTask",
        name: "consultantTask",
        component: () => import("@/views/home/TaskManagement/ConsultantTask/index"),
        meta: {
          name: "Task管理",
        },
      },
      {
        path: "/benchTask",
        name: "benchTask",
        component: () =>
          import("@/views/home/TaskManagement/BenchTask/index.vue"),
        meta: {
          name: "团队管理",
        },
      },
      {
        path: "/benchTask/lmBenchTaskDetail",
        name: "lmBenchTaskDetail",
        component: () =>
          import(
            "@/views/home/TaskManagement/BenchTask/component/newBenchTask.vue"
          ),
        meta: {
          name: "任务看板",
        },
      },
      {
        path: "/benchTask/consultantbenchTaskDetail",
        name: "consultantbenchTaskDetail",
        component: () =>
          import(
            "@/views/home/TaskManagement/BenchTask/component/consultantTasks.vue"
          ),
        meta: {
          name: "任务看板",
          keepAlive: false,
        },
      },

      {
        path: "/notice",
        name: "notice",
        component: () => import("@/views/home/Notice/index.vue"),
        meta: {
          name: "消息通知",
        },
      },
      {
        path: "/consultantAssignment",
        name: "consultantAssignment",
        component: () =>
          import("@/views/home/TaskManagement/assignmentDetails"),
        meta: {
          name: "调令",
        },
      },
      {
        path: "/assignment",
        name: "Assignment",
        component: () =>
          import("@/views/home/ResourceManagement/Assignment/index.vue"),
        meta: {
          name: "调令管理",
        },
      },
      {
        path: "/assignmentDetails",
        name: "assignmentDetails",
        component: () =>
          import(
            "@/views/home/ResourceManagement/Assignment/assignmentDetails/index.vue"
          ),
        meta: {
          name: "调令详情",
        },
      },
      {
        path: "/infoMaintain/organization",
        name: "maintainOrganization",
        component: () => import("@/views/home/BaseDataManagement/Organization"),
        meta: {
          name: "成本中心",
        },
      },
      {
        path: "/infoMaintain/account",
        name: "maintainAccount",
        component: () => import("@/views/home/BaseDataManagement/Account"),
        meta: {
          name: "Account主数据",
        },
      },
      {
        path: "/infoMaintain/permission",
        name: "maintainPermission",
        component: () => import("@/views/home/ConfigManagement/Permission"),
        meta: {
          name: "权限管理",
        },
      },
      {
        path: "/infoMaintain/role",
        name: "maintainRole",
        component: () => import("@/views/home/ConfigManagement/Role"),
        meta: {
          name: "角色权限",
        },
      },
      {
        path: "/infoMaintain/roleManagement",
        name: "roleManagement",
        component: () => import("@/views/home/ConfigManagement/RoleManagement"),
        meta: {
          name: "角色管理",
        },
      },
      {
        path: "/infoMaintain/leadershipWeight",
        name: "maintainLeadershipWeight",
        component: () =>
          import("@/views/home/ConfigManagement/LeadershipWeight"),
        meta: {
          name: "领导力权重管理",
        },
      },
      {
        path: "/infoMaintain/user",
        name: "maintainUser",
        component: () => import("@/views/home/ConfigManagement/User"),
        meta: {
          name: "人员角色",
        },
      },
      {
        path: "/infoMaintain/pm",
        name: "maintainPm",
        component: () => import("@/views/home/ResourceManagement/PM"),
        meta: {
          name: "项目管理",
        },
      },
      {
        path: "/infoMaintain/empStatus",
        name: "maintainEmpStatus",
        component: () => import("@/views/home/ResourceManagement/EmpStatus"),
        meta: {
          name: "人员状态",
        },
      },
      {
        path: "/infoMaintain/dictionary",
        name: "maintainDictionary",
        component: () => import("@/views/home/ConfigManagement/Dictionary"),
        meta: {
          name: "数据字典",
        },
      },
      {
        path: "/staffFile/getFile/cgChinaCvTemplate",
        name: "resumeTemplateUpdated",
        component: () => import("@/views/home/ConfigManagement/ResumeTemplateUpdated"),
        meta: {
          name: "简历模板更新",
        },
      },
      {
        path: "/infoMaintain/reviewer",
        name: "maintainReviewer",
        component: () => import("@/views/home/ConfigManagement/Reviewer"),
        meta: {
          name: "数据权限管理(Reviewer)",
        },
      },
      {
        path: "/infoMaintain/userManagement",
        name: "maintainUserManagement",
        component: () => import("@/views/home/ConfigManagement/UserManagement"),
        meta: {
          name: "用户管理",
        },
      },
      {
        path: "/infoMaintain/dataPerms",
        name: "maintainDataPerms",
        component: () => import("@/views/home/ConfigManagement/DataPerms"),
        meta: {
          name: "数据权限",
        },
        children: [
          {
            path: "/infoMaintain/dataPermsByCostCtr",
            name: "maintainDataPermsByCostCtr",
            component: () =>
              import(
                "@/views/home/ConfigManagement/DataPerms/DataPermsByCostCtr"
              ),
            meta: {
              name: "人员权限管理",
            },
          },
          {
            path: "/infoMaintain/dataPermsByPerson",
            name: "maintainDataPermsByPerson",
            component: () =>
              import(
                "@/views/home/ConfigManagement/DataPerms/DataPermsByPerson"
              ),
            meta: {
              name: "用户管理",
            },
          },
        ],
      },
      {
        path: "/infoMaintain/code",
        name: "codeManagement",
        component: () => import("@/views/home/BaseDataManagement/CodeManagement"),
        meta: {
          name: "Code主数据",
        },
      },
      {
        path: "/infoMaintain/orientationguidelines",
        name: "orientationGuidelines",
        component: () => import("@/views/home/BaseDataManagement/OrientationGuidelines"),
        meta: {
          name: "入职指引",
        },
      },
      {
        path: "/infoMaintain/template",
        name: "template",
        component: () => import("@/views/home/WelLetterManagement"),
        meta: {
          name: "欢迎信模板",
        },
      },
      {
        path: "/infoImport/cost",
        name: "importCost",
        component: () => import("@/views/home/BaseDataManagement/Cost"),
        meta: {
          name: "成本信息",
        },
      },
      {
        path: "/infoImport/bpoPersonal",
        name: "importBpoPersonal",
        component: () => import("@/views/home/BaseDataManagement/BpoPersonal"),
        meta: {
          name: "人员信息",
        },
      },
      {
        path: "/infoExport/assignment",
        name: "exportAssignment",
        component: () => import("@/views/home/BaseDataManagement/Assignment"),
        meta: {
          name: "调令信息",
        },
      },
      {
        path: "/infoExport/workTask",
        name: "workTask",
        component: () => import("@/views/home/BaseDataManagement/WorkTask"),
        meta: {
          name: "TASK信息",
        },
      },
      {
        path: "/infoExport/certificate",
        name: "exportCertificate",
        component: () => import("@/views/home/BaseDataManagement/Certificate"),
        meta: {
          name: "证书信息",
        },
      },
      {
        path: "/staffInfoChangeHistory/pageSearch",
        name: "pageSearch",
        component: () => import("@/views/home/BaseDataManagement/History"),
        meta: {
          name: "变更记录",
        },
      },
      {
        path: "/infoExport/cv",
        name: "exportCv",
        component: () => import("@/views/home/BaseDataManagement/CV"),
        meta: {
          name: "简历统计",
        },
      },
      {
        path: "/infoExport/match",
        name: "exportMatch",
        component: () => import("@/views/home/BaseDataManagement/Match"),
        meta: {
          name: "匹配信息",
        },
      },
      {
        path: "/infoExport/skill",
        name: "exportSkill",
        component: () => import("@/views/home/BaseDataManagement/Skill"),
        meta: {
          name: "技能信息",
        },
      },
      {
        path: "/infoExport/task",
        name: "exportTask",
        component: () => import("@/views/home/BaseDataManagement/BenchTask"),
        meta: {
          name: "任务信息",
        },
      },
      {
        path: "/infoExport/roleReview",
        name: "exportRoleReview",
        component: () => import("@/views/home/BaseDataManagement/RoleReview"),
        meta: {
          name: "岗位信息",
        },
      },
      {
        path: "roleMaintain",
        name: "roleMaintain",
        component: () => import("@/views/home/RoleMaintain/index.vue"),
        meta: {
          name: "技能&岗位审核",
        },
      },
      {
        path: "/todoList",
        name: "todoList",
        component: () => import("@/views/home/TodoList/index.vue"),
        meta: {
          name: "待办列表",
        },
      },
      {
        path: "staffBoard",
        name: "staffBoard",
        component: () => import("@/views/home/ResourceManagement/RM/index.vue"),
        meta: {
          name: "人员管理",
        },
      },
      {
        path: "/rmBoard/staffInfo",
        name: "staffInfo",
        component: () =>
          import("@/views/home/ResourceManagement/RM/StaffInfo/index.vue"),
        meta: {
          name: "人员详情",
        },
      },
      {
        path: "/ARVE/GTEData",
        name: "GTEData",
        component: () => import("@/views/home/ARVE/GTEData/index.vue"),
        meta: {
          name: "GTE数据",
        },
      },
      {
        path: "/ARVE/TeamReport",
        name: "teamReport",
        component: () => import("@/views/home/ARVE/TeamReport/index"),
        meta: {
          name: "团队报表",
        },
      },
      {
        path: "/ARVE/TargetReport",
        name: "TargetReport",
        component: () => import("@/views/home/ARVE/TargetReport/index"),
        meta: {
          name: "目标管理",
        },
      },
      {
        path: "/ARVE/PersonalReport",
        name: "personalReport",
        component: () => import("@/views/home/ARVE/PersonalReport/index"),
        meta: {
          name: "个人报表",
        },
      },
      {
        path: "/leadershipManagement",
        name: "LeadershipManagement",
        component: () => import("@/views/home/LeadershipManagement"),
        meta: {
          name: "领导力管理",
        },
      },
      {
        path: "/italent/report",
        name: "ItalentReport",
        component: () => import("@/views/home/ItalentReport"),
        meta: {
          name: "北森需求",
        },
      },
      {
        path: "/staffannouncement/upgradeNotification",
        name: "UpgradeNotice",
        component: () => import("@/views/home/ConfigManagement/UpgradeNotice"),
        meta: {
          name: "升级通知",
        }
      },
      {
        path: "/assignmentInfo/assignmentsEMReview",
        name: "AssignmentsEMReview",
        component: () => import("@/views/home/AssignmentsEMReview"),
        meta: {
          name: "调令评价",
        }
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: () => import("@/views/home/Dashboard"),
        meta: {
          name: "Dashboard",
        }
      },
      {
        path: "/seat/application",
        name: "seatApplication",
        component: () => import("@/views/home/Seat/SeatApplication"),
        meta: {
          name: "座位申请",
        }
      },
      {
        path: "/my/application",
        name: "myApplication",
        component: () => import("@/views/home/Seat/MyApplication"),
        meta: {
          name: "我的申请",
        }
      },
      {
        path: "/my/seat",
        name: "mySeat",
        component: () => import("@/views/home/Seat/MySeat"),
        meta: {
          name: "我的座位",
        }
      },
      {
        path: "/seat/approval",
        name: "seatApproval",
        component: () => import("@/views/home/Seat/SeatApproval"),
        meta: {
          name: "座位审批",
        }
      },
      {
        path: "/seat/usage",
        name: "seatUsage",
        component: () => import("@/views/home/Seat/SeatUsage"),
        meta: {
          name: "使用情况",
        }
      },
      {
        path: "/seat/seatStatisticsDetails",
        name: "seatStatisticsDetails",
        component: () => import("@/views/home/Seat/SeatUsage/seatStatisticsDetails"),
        meta: {
          name: "座位统计详情",
        },
      },
      {
        path: "/infoMaintain/addPermissions",
        name: "addPermissions",
        component: () => import("@/views/home/ConfigManagement/AddPermissions"),
        meta: {
          name: "后台新增权限",
        },
      },
      {
        path: "/infoMaintain/emailManagement",
        name: "emailManagement",
        component: () => import("@/views/home/ConfigManagement/EmailManagement"),
        meta: {
          name: "邮件管理",
        },
      },
      {
        path: "/downloadtask",
        name: "downloadtask",
        component: () => import("@/views/home/FileTransferStation/index.vue"),
        meta: {
          name: "文件中转站",
        },
      },
      {
        path: "/ARVE/FCSTReport",
        name: "FCSTReport",
        component: () => import("@/views/home/ARVE/FCSTReport/index"),
        meta: {
          name: "FCST报表",
        },
      },
    ],
  },
  // {
  //   path: "/404",
  //   name: "404",
  //   component: () => import("@/views/error/404"),
  // },
  {
    path: "/401",
    name: "401",
    component: () => import("@/views/error/401"),
  },
  {
    path: `/singleSignOn/:id`,
    name: "singleSignOn",
    component: () => import("@/views/singleSignOn/index"),
  },
  {
    path: `/singleSignOn/evaluation/:id`,
    name: "evaluation",
    component: () => import("@/views/evaluation/index"),
  },
  {
    path: `/singleSignOn/expirationInformation/:id`,
    name: "expirationInformation",
    component: () => import("@/views/evaluation/index"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/login"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: "/rms/",
});

export default router;
