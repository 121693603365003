<template>
  <section
    :class="['side-nav-left', collapsed ? 'side-nav-left-collapsed' : '']"
  >
    <div class="menu">
      <a-menu
        class="menu-inline"
        mode="horizontal"
        :inline-collapsed="collapsed"
        v-model:openKeys="openKeys"
        v-model:selectedKeys="selectedKeys"
        @click="click"
        @openChange="openMenu"
        triggerSubMenuAction="click"
      >
        <template v-for="item in $store.state.app.menusList" :key="item.id">
          <template v-if="!item.childMenus">
            <a-menu-item :key="item.url">
              <router-link :to="item.url">
                <span class="subMenuTitle">{{ item.title }}</span>
              </router-link>
            </a-menu-item>
          </template>
          <template v-else>
            <a-sub-menu popupClassName="special-bg-font-color" :key="item.id">
              <template #title>
                <span class="subMenuTitle">{{ item.title }}</span>
              </template>
              <template v-for="item in item.childMenus" :key="item.id">
                <router-link :to="item.url">
                  <a-menu-item :key="item.url">
                    <span class="menuTitle">{{ item.title }}</span>
                  </a-menu-item>
                </router-link>
              </template>
            </a-sub-menu>
          </template>
        </template>
      </a-menu>
    </div>
  </section>
</template>

<script>
import {
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  watch,
} from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components: {},
  setup() {
    const click = ({ keyPath }) => {
      sessionStorage.setItem("subMenuKey", JSON.stringify([keyPath[0]]));
    };
    const openMenu = (openKeys)=>{
      console.log(openKeys)
    }
    const route = useRoute();
    const state = reactive({
      collapsed: false,
      openKeys: [""],
      selectedKeys: [route.path],
      preOpenKeys: [""],
    });

    watch(
      () => route.path,
      () => {
        if (route.path === "/newMatch") {
          return (state.selectedKeys = ["/matchingList"]);
        }
        if (route.path === "/rmBoard/staffInfo") {
          return (state.selectedKeys = ["/staffBoard"]);
        }
        state.selectedKeys = [route.path];
      },
      { immediate: true }
    );

    watch(
      () => state.openKeys,
      (val, oldVal) => {
        state.preOpenKeys = oldVal;
      }
    );

    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
      state.openKeys = state.collapsed ? [] : state.preOpenKeys;
    };

    onMounted(() => {
      //const str = sessionStorage.getItem("subMenuKey"); //刷新后 选中菜单绑定
      //if (str) state.openKeys = JSON.parse(str);
      //handlerSwitchRole()
    });
    onUnmounted(() => {
      sessionStorage.clear();
    });

    return {
      click,
      openMenu,
      ...toRefs(state),
      toggleCollapsed,
    };
  },
});
</script>
<style lang="less" scoped>

.menu{
  margin-top: 4px
}

.icon {
  color: #3182ce;
}

:deep(.ant-menu-horizontal) {
  border-bottom: 0;
}

.side-nav-left {

  :deep(.ant-menu-item, .ant-menu-submenu-title){
    position: relative
  }

  :deep(.ant-menu-horizontal) {
    line-height: 2.7vw;
    color:#5d7380;
    border-bottom: 0;
     .ant-menu-item a{
       color:#5d7380;
       border-bottom: 0;
     }
    .ant-menu-item-selected a{
      color:rgb(23,130,227);
    }
    .ant-menu-item a:hover{
      color:rgb(23,130,227);
    }

    .ant-menu-submenu-arrow {
      position: absolute;
      top: 50%;
      right: -0.96667vw;
      width: 0.52083vw;
      color: rgba(0, 0, 0, 0.85);
      transform: translateY(-50%);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      display: flex;

      &::before {
        transform: rotate(-45deg) translateX(0.13021vw);
      }

      &::after {
        transform: rotate(45deg) translateX(-0.13021vw);
        position: absolute;
        width: 0.3125vw;
        height: 0.07813vw;
        background-color: currentColor;
        border-radius: 0.10417vw;
        transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        content: '';
      }
    }
  }

  :deep(.ant-menu-submenu-open) {

    .ant-menu-submenu-arrow {
      position: absolute;
      top: 50%;
      right: -0.96667vw;
      width: 0.52083vw;
      color: rgba(0, 0, 0, 0.85);
      transform: translateY(-0.10417vw);
      transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      display: flex;

      &::before {
        transform: rotate(45deg) translateX(0.13021vw)
      }

      &::after {
        position: absolute;
        width: 0.3125vw;
        height: 0.07813vw;
        background-color: currentColor;
        border-radius: 0.10417vw;
        transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        content: '';
        transform: rotate(-45deg) translateX(-0.13021vw);
      }
    }
  }

  :deep(.ant-menu-horizontal .ant-menu-submenu){
    &::after{
      border-bottom: 0;
    }
  }
  :deep(.ant-menu-horizontal .ant-menu-item){
    &::after{
      border-bottom: 0;
    }
  }
  :deep(.ant-menu-horizontal .ant-menu-submenu:hover){
    &::after{
      border-bottom: 0;
    }
  }

  :deep(.ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-selected){
    color: rgb(23,130,227);
    &::after{
      border-bottom: 0;
    }
  }
  :deep(.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open){
    &::after{
      border-bottom: 0;
    }
  }
  :deep(.ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item-selected){
    color:rgb(23,130,227);
    &::after{
      border-bottom: 0;
    }
  }
  :deep(.ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item-active){
    color:rgb(23,130,227);
    &::after{
      border-bottom: 0;
    }
  }
  :deep(.ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-active){
    color: rgb(23,130,227);
    border-bottom: 0;
    &::after{
      border-bottom: 0;
    }
  }
  :deep(.ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-item:hover){
    &::after{
      border-bottom: 0;
    }
  }
}
</style>
<style lang="less">
.special-bg-font-color.ant-menu {
  top: 53px !important;

  // 自定义独属全局样式
  .ant-menu {
    .ant-menu-item-selected {
      background-color: #ECF5FE;
      //margin: 0 0.4vw;

      .menuTitle {
        color: var(--333333, #333);
      }
    }
    .ant-menu-horizontal:not(.ant-menu-dark) .ant-menu-submenu-selected{
      &::after{
        border-bottom: 0;
      }
    }
  }

  .menuTitle {
    color: #5d7380;
  }
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip {
  // ant组件未提供口子，只能写非独属全局
  .ant-tooltip-inner {
    background: #fff;
  }

  .ant-tooltip-arrow-content {
    background: #fff;
  }

  .subMenuTitle {
    color:#5d7380;
  }

  .anticon {
    display: none;
  }
}
.subMenuTitle {
  font-size: 16px;
  font-weight: 400;
}
</style>
