import request from "@/utils/http";
// 获取字典列表
export function getDictionaryList(params) {
  return request.get(`staffDictionary`, params);
}

// 查看各种变更记录
export function getAllKindRecordList(params) {
  return request.get(`/histories/query`, { params });
}

// 查询自定义筛选框顺序接口
export function queryFilterSort(params) {
  return request.post(`/customSort/queryFilterSort`, params);
}
// 重置自定义筛选框顺序接口
export function resetFilterSort(params) {
  return request.post(`/customSort/resetFilterSort`, params);
}

// 保存自定义筛选框顺序接口
export function saveFilterSort(params) {
  return request.post(`/customSort/saveFilterSort`, params);
}
