import request from "@/utils/http";
// 查询入职指引列表
export function queryOrgInfoByCondition(data) {
    return request.post(`/organizations/queryOrgInfoByCondition`, data);
}
// 上传文件
export function uploadOrganizations(data, params) {
    return request.post("/organizations/upload", data, {
        params,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
// 下载
export function onboardGuideDownloadFile(id) {
    return request.get(`/organizations/onboardDownloadFile/${id}`, { responseType: "blob" });
}
// 删除
export function organizationsDeleteFile(fileId,offering) {
    return request.get(`/organizations/deleteFile?fileId=${fileId}&offering=${offering}`);
}