import request from "@/utils/http";

export function getPerNum() {
  return request.get("/dashboard/headcount");
}

// 保存任务
export function saveTask(data) {
  return request.post("/taskInfos/add", data);
}

// 查看任务明细
export function queryTask(params) {
  return request.get("/taskInfos/query", { params });
}

// 查询LineManager下的顾问
export function queryEmpByLm(params) {
  return request.get("/taskInfos/queryByLm", { params });
}

// 保存顾问答案信息
export function saveTaskAnswer(data) {
  return request.post("/taskInfos/saveAnswer", data);
}

// 上传文件
export function uploadTaskFile(data, params) {
  return request.post("/files/upload", data, {
    params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// 查询任务下所有的LM文件
export function getTaskLmFileList(params) {
  return request.get("/files/LM", { params });
}

// 查询任务下所有的顾问文件
export function getTaskConsultantFileList(params) {
  return request.get("/files/consultant", { params });
}

// 删除文件
export function delTaskFile(id) {
  return request.get(`/files/deleteFile/${id}`);
}

// 下载文件
export function downloadTaskFile(id) {
  return request.get(`/files/downloadFile/${id}`, { responseType: "blob" });
}

// //任务获取
// export function getBenchTaskList() {
//   return request.get("/lanes/tasks");
// }
//匹配筛选
export function getChooseList(data) {
  return request.post("/lanes/tasks", data);
}

//任务删除
export function delectBenchTaskList(params) {
  return request.post("/taskInfos/delete", {}, { params });
}

//任务接受
export function receiveBenchTaskList(params) {
  return request.post("/taskInfos/receive", {}, { params });
}

//驳回任务
export function rejectBenchTaskList(params) {
  return request.post("/taskInfos/reject", {}, { params });
}

//通过任务
export function passBenchTaskList(params) {
  return request.post("/taskInfos/pass", {}, { params });
}

//任务发布
export function publishBenchTaskList(params) {
  return request.post(`/taskInfos/publish`, {}, { params });
}

//任务提交
export function commitBenchTaskList(params) {
  return request.post("/taskInfos/commit", {}, { params });
}

//任务复制
export function copyBenchTaskList(params) {
  return request.post("/taskInfos/copyTask", {}, { params });
}

// 保存评论信息
export function saveTaskComment(data) {
  return request.post("/taskInfos/saveComment", data);
}

// 查询评论+头像
export function getTaskComment(params) {
  return request.get("/taskInfos/comments", { params });
}

//查看LM下面顾问任务状态
export function getTaskStatus(params) {
  return request.get("/dashboard/tasksHeadcount", { params });
}

//顾问查看任务
export function getconsultantTasks() {
  return request.get("/lanes/consultantTasks");
}
//顾问查看任务(消息跳转后的)
export function consultantTasksById(params) {
  return request.get(`/lanes/consultantTasksById?taskId=${params}`);
}
// 任务管理-顾问任务管理查询
export function getConsultantTaskList(data) {
  return request.post("/works/consultantList", data);
}

