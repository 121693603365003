import request from "@/utils/http";

// 获取需求信息
export function getDemandInfo(params) {
  return request.get(`/demandInfos`, { params });
}

// 顾问接收
export function empConsultant(params) {
  return request.post(`/assignments/consultant`, {}, { params });
}

// 通过顾问CRC获取人员成本ADRC
export function getADRC(params) {
  return request.get(`/assignments`, { params });
}

// PM查看简历后同意面试
export function pmAccept(data) {
  return request.post(`/assignments/pm`, data);
}

// PM查看简历后拒绝
export function pmCVInterview(params) {
  return request.post(`/assignments/pmCVInterview`, {}, { params });
}

// 保存PM填写调令信息
export function pmCVReject(params) {
  return request.post(`/assignments/pmCVReject`, {}, { params });
}

// PM面试不通过
export function pmInterviewFail(params) {
  return request.post(`/assignments/pmInterviewFail`, {}, { params });
}

// 查询PM调令信息
export function getAssignmentInfo(preMatchingId) {
  return request.get(`/assignments/${preMatchingId}`);
}

// Assignment模块查询调令信息
export function getAssignmentById(params) {
  return request.get(`/assignments/byId`, { params });
}

// 更新RM确定Assignment信息
export function rmConfirm(url, data) {
  return request.post(url, data);
}

// RM驳回
export function rmReject(data) {
  return request.post(`/assignments/reject`, data);
}

// Assignment判断时间重叠
export function isTimeJudgments(data) {
  return request.post(`/assignments/isContinue`, data);
}

//
export function resourceExportList(data) {
  return request.post("/staffAllInfo/list", data);
}

export function resourceExport(data) {
  return request.post("/staffAllInfo/export", data, { responseType: "blob" });
}



export function queryExcelSort(flag) {
  return request.get(`/customSort/queryExcelSort?isDefault=${flag}`);
}

export function saveExcelHeader(params) {
  return request.post("/customSort/saveExcelHeader",params);
}

export function queryDefaultExcelHeader(type) {
  return request.get(`/customSort/queryDefaultExcelHeader?headerType=${type}`);
}


// RM驳回
export function rmRevert(params) {
  return request.post(`/matchings/revert`, params);
}
// 获取clientName 和 industry
export function getCaptives(params) {
  return request.get(`/captives`, { params });
}
// 上传附件
export function uploadAnnex(data, params) {
  return request.post("/annexes/upload", data, {
    params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
//保存
export function saveAssignment(data) {
  return request.post(`/assignments/save`, data);
}
//获取保存后的信息
export function preMatchingIdTemporary(params) {
  return request.post(`/assignments/preMatchingIdTemporary`, {}, { params });
}

// 批量修改ARVE
export function batchChangeArve(data) {
  return request.post(`/matchings/updateARVE`, data);
}

// 修改ARVE后更新数据
export function afterBatchChangeUpdate(data){
  return request.post(`/matchings/queryARVE`, data);
}
