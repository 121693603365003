import request from "@/utils/http";

// 查询Code列表
export function getCodeListApi(data, params) {
    return request.post(`/codeData/list`, data, { params });
}
//查询TBDCode列表
export function getTBDCodeListApi(data, params) {
    return request.post(`/codeTbdData/list`, data, { params });
}
//查询account,p&l,sub_p&l
export function getAccountPlItemsApi() {
    return request.get(`/accountData/items`);
}
export function getAccountPlsApi() {
    return request.get(`/accountData/relations`);
}
// Code管理 下载模板
export function getCodeTemplate() {
    return request.get("/codeData/exportTemplate", { responseType: "blob" });
}
// TBD Code管理 下载模板
export function getTbdCodeTemplate() {
    return request.get("/codeTbdData/exportTemplate", { responseType: "blob" });
}
// Code管理 导出
export function exportCodeData(data) {
    return request.post(`/codeData/export`, data, {
        responseType: "blob",
    });
}
// Code管理 导入
export function getCodeImport(data) {
    return request.post("/codeData/import", data);
}
// TBD Code管理 导入
export function getTBDCodeImport(data) {
    return request.post("/codeTbdData/import", data);
}
// TBD Code管理 导出
export function exportTbdCodeData(data) {
    return request.post(`/codeTbdData/export`, data, {
        responseType: "blob",
    });
}
// 获取最近一次导入的数据的信息 code管理
export function getcodeStatusData(params) {
    return request.get("/codeData/import/status", { params });
  }
// 获取最近一次导入的数据的信息 TBD code管理
export function getcodeTbdStatusData(params) {
    return request.get("/codeTbdData/import/status", { params });
  }
