import request from "@/utils/http";

export function getRoleInfo() {
  return request.get(`/roles/roleInformation`);
}

export function getRoleIdsByStaffId(params) {
  return request.get(`/roles/roleInfo`, { params });
}

export function getStaffRoleInfo(data) {
  return request.post(
    `/roles/staffInformation?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}`,
    data
  );
}

// export function getStaffs(data) {
//   return request.post(
//     `/roles/staffInfo?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}`,
//     data
//   );
// }

export function getStaffs(params) {
  return request.get(`/roles/staffInfo`, { params });
}

export function getPermsByRoleId(params) {
  return request.get(`/roles/permsByRoleId`, { params });
}

export function getPermsList(params) {
  return request.get(`/roles/findPermissions`, { params });
}

export function updateStaffRolePerms(params) {
  return request.post(`/roles/updatePermissions`, params);
}

export function updateUserRole(params) {
  return request.post(`/roles/modifyPermissions`, params);
}

// 权限信息查询
export function getPermissionList(data) {
  return request.post(`/staffPerms/queryPerms`, data);
}

// 新增权限信息
export function addPermission(data, params) {
  return request.post(`/staffPerms/addPerm`, data, { params });
}

// 删除权限信息
export function delPermission(data, params) {
  return request.post(`/staffPerms/deletePerm`, data, { params });
}

// 更改权限信息
export function updatePermission(data, params) {
  return request.post(`/staffPerms/updatePerm`, data, { params });
}

//PM变更列表
export function getPmsList(data) {
  return request.post("/pms", data);
}

//PM变更
export function modifyPM(params) {
  return request.post("/pms/modify", params);
}

export function resetStatus(params) {
  return request.post(`/works/single`, {}, { params });
}

export function resetAllStatus() {
  return request.post(`/works/async`);
}

export function resetAllAvailability() {
  return request.post(`/userstatus/availability`);
}

export function getEmpStatus(params) {
  return request.get("/userstatus/update/status", { params });
}

export function searchStatus(params) {
  return request.get(`/status/query`, { params });
}

// 通过类型查询字典，可分页
export function getDictionaryMaintainList(params) {
  return request.get("/staffDictionary/categories", { params });
}

// 分通用和部门查询技能
export function getSkillMaintainList(params) {
  return request.get("/staffDictionary/skillsCategories", { params });
}

// 技能分部门或分类别下拉框
export function skillsComboBox(params) {
  return request.get("/staffDictionary/skillsComboBox", { params });
}

// 查询所有证书
export function getCtfMaintainList(params) {
  return request.get("/staffCtf", { params });
}

// 证书group下拉框
export function ctfComboBox(params) {
  return request.get("/staffCtf/ctfComboBox", { params });
}

// 单类字典添加
export function addDictionary(data) {
  return request.post("/staffDictionary/addDictionary", data);
}

// 单类字典更新
export function updateDictionary(data) {
  return request.post("/staffDictionary/updateDictionary", data);
}

// 导出字典模板
export function exportDicTemplate(params) {
  return request.get("/staffDictionary/exportDicTemplate", {
    responseType: "blob",
    params,
  });
}

// 导出字典数据
export function exportDictionaries(params, exportUrl) {
  return request.get(exportUrl, {
    responseType: "blob",
    params,
  });
}

// 导入字典数据
export function importDictionaries(data, params) {
  return request.post("/staffDictionary/importDictionaries", data, { params });
}

// 添加证书
export function addCtf(data) {
  return request.post("/staffCtf", data);
}

// 更新证书
export function updateCtf(data) {
  return request.post("/staffCtf/update", data);
}

// 导出证书数据
export function exportStaffCtf() {
  return request.get("/staffCtf/exportStaffCtf", {
    responseType: "blob",
  });
}

// Reviewer维护查询
export function getReviewerList(params) {
  return request.get("/skills/reviewers", { params });
}

// 查询cost ctr Reviewer
export function getCtrReviewerList(params) {
  return request.get("/skills", { params });
}

// Reviewer维护
export function saveReviewer(data) {
  return request.post("/skills/save", data);
}

//删除除证书以外的字典
export function delOtherCtf(data) {
  return request.post("/staffDictionary/deleteDictionary", data);
}

//删除证书
export function delCtf(data) {
  return request.post("/staffCtf/delete", data);
}

// 查询领导力权重管理配置
export function getWeight() {
  return request.get("/weight/info");
}

// 更新领导力权重管理配置
export function updateWeight(params) {
  return request.post("/weight/update", params);
}

//获取taskType和taskDetail
export function getWorkTaskComboBox() {
  return request.get("/staffDictionary/workTaskComboBox");
}

//获取taskDetail
export function getTaskDetail(data) {
  return request.post("/staffDictionary/taskDetail", data);
}

//新建task
export function addNewTask(data) {
  return request.post("/workTasks/save", data);
}

//task时间重叠校验
export function workTaskOverlap(data) {
  return request.post("/workTasks/overlap", data);
}

//新建task
export function invalidTask(params) {
  return request.post("/works/invalid", {}, { params });
}

//获取task人数统计
export function getWorkTaskCount(data) {
  return request.post("/workTasks/count", data);
}

//获取Availability统计
export function getAvailabilityStatistics(data) {
  return request.post("/workTasks/availabilityStatistics", data);
}

//获取调令类型统计
export function getAssignmentTypeStatistics(data) {
  return request.post("/workTasks/assignmentTypeStatistics", data);
}

//查询单条task信息
export function getTaskInfo(params) {
  return request.get("/works/query", { params });
}

//RM获取自己有权限人员的姓名邮箱IdNumber
export function getConsultantList(params) {
  return request.get("/staffInfos/perms", { params });
}

//新建task
export function getTaskList(data) {
  // return request.post("/works/list", data);
  return request.post("/works/rmAndRMLeaderList", data);
}

//查询行业线
export function getIndustryLineList(params) {
  return request.get("/staffPlInfo/plInfoData", { params });
}

// 导出行业线模版
export function exportIndustryLineTemplate() {
  return request.get("/staffPlInfo/exportPlInfoTemplate", {
    responseType: "blob",
  });
}

// 导入行业线数据
export function importIndustryLine(data, params) {
  return request.post("/staffPlInfo/importPlInfoData", data, { params });
}
//annual leave和other leave时间重叠校验
export function specialLeaveOverlap(data) {
  return request.post("/workTasks/overlap/specialLeave", data);
}
// 获取用户管理列表数据
export function getUserRoleInfos(data) {
  return request.post("/roles/userRoleInfos", data);
}
// 用户管理角色下拉列表
export function getRoleList(params) {
  return request.get("/roles/roleList", { params });
}

// 查询role
export function getRolesList(params) {
  return request.get("/staffDictionary/rolecategories", { params });
}

// 新增role
export function addRoleDictionary(data) {
  return request.post("/staffDictionary/addRoleDictionary", data);
}

// 编辑role
export function updateRoleDictionary(data) {
  return request.post("/staffDictionary/updateRoleDictionary", data);
}
// 删除role
export function delRoleDic(data) {
  return request.post("/staffDictionary/deleteRoleDictionary", data);
}
//用户管理导出
export function userRoleExport(data) {
  return request.post("/roles/userRoleInfos/export", data, {
    responseType: "blob",
  });
}
//用户管理详情页用户权限列表数据
export function getUserPerms(params) {
  return request.get("/roles/getUserPerms", { params });
}
//用户管理详情页复制时校验角色互斥
export function checkCopyRoleMutex(data) {
  return request.post("/dataperms/checkCopyRoleMutex", data);
}
//复制角色权限
export function copyRolePerms(data) {
  return request.post("/dataperms/copyRolePerms", data);
}
//offering和cost_ctr关系
export function costCtrAndOfferingRelations(params) {
  return request.get("/organizations/costCtrAndOfferingRelations", { params });
}

//用户管理offering和Cost_ctr数据
export function getCostCtrAndOfferingItems() {
  return request.get("/organizations/costCtrAndOfferingItems");
}
//删除用户权限
export function deleteUserPerms(params) {
  return request.get("/dataperms/deleteUserPerms", { params });
}
//编辑用户权限
export function editUserPerms(data) {
  return request.post("/dataperms/editUserPerms", data);
}
//编辑时校验角色互斥
export function checkEditRoleMutex(data) {
  return request.post("/dataperms/checkEditRoleMutex", data);
}
//菜单
export function allPermLevelsMenu(params) {
  return request.get("/staffPerms/allPermLevelsMenu", { params });
}
//查询
export function queryMenuInfoAndBtnInfo(data) {
  return request.post("/staffPerms/queryMenuInfoAndBtnInfo", data);
}
//启用停用
export function queryStartUsingAndEndUsing(data) {
  return request.post("/staffPerms/queryStartUsingAndEndUsing", data);
}

//获得角色互斥关系
export function queryRoleMutexAndRole(params) {
  return request.get("/dataperms/queryRoleMutexAndRole", { params });
}
//项目管理-查询顾问信息接口
export function queryConsultant(data) {
  return request.post("/pms/queryConsultant", data);
}
//项目管理-pm变更
export function consultantPmChange(data) {
  return request.post("/pms/consultantPmChange", data);
}
//项目管理-pm批量变更
export function PmChangeModify(data) {
  return request.post("/pms/modify", data);
}
//项目管理-pm变更记录
export function pmHistories(params) {
  return request.get("/histories/queryPmChange", {params});
}
//成本中心-获取状态
export function organizationsStatus() {
  return request.get("/organizations/importOrg/status");
}
//成本信息-获取状态
export function costexcelsStatus() {
  return request.get("/costexcels/importCosts/status");
}
