import request from "@/utils/http";

// 获取邮件管理列表
export function getEmailInfo(data) {
    return request.post("emailManagement/info", data);
}
// 获取邮件模板状态
export function getEmailType(data) {
    return request.post("/emailManagement/getEmailType", data);
}
// 勾选停用
export function checkOrDisable(data) {
    return request.post("/emailManagement/checkOrDisable", data);
}
// 配置查询
export function getEmailManagementId(data) {
    return request.post("/emailManagement/emailManagementId", data);
}
// 配置确认
export function updateSendMailDaysById(data) {
    return request.post("/emailManagement/updateSendMailDaysById", data);
}
// 获取RM列表
export function getRmList(data) {
    return request.post("/rmList/getRmList", data);
}
// 查询现有RM信息
export function getCurrentRm(data) {
    return request.get("/rmList/getCurrentRm", data);
}
// 删除
export function delRmList(data) {
    return request.post("/rmList/delRmList", data);
}
// 编辑新增
export function saveRmList(data) {
    return request.post("/rmList/saveRmList", data);
}
