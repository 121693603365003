// import moment from "moment";

export function fillZero(num) {
  let realNum;
  if (num < 10) {
    realNum = "0" + num;
  } else {
    realNum = num;
  }
  return realNum;
}

export function downloadFile(blob, filename) {
  const downloadElement = document.createElement("a");
  const href = window.URL.createObjectURL(blob);
  downloadElement.href = href;
  downloadElement.download = filename;
  downloadElement.click();
  window.URL.revokeObjectURL(href);
}

export function useTableHeight(
  isHavePagination = true,
  tableClass = ".ant-table",
  headHeight,
  headFatherId,
  otherHeightVal,
) {
  let tableHeight = 300;
  let paginationHeight = 0;
  try {
    // const clientWidth = document.body.clientWidth;
    const clientHeight = document.body.clientHeight;
    const tableBodyOffsetTop = document
      .querySelector(tableClass)
      .getBoundingClientRect().top;
    let tableTheadHeight;

    // 避免页面内有两个table,所以加一个fatherId用于区分不同table
    if(headFatherId) {
      tableTheadHeight = headHeight
          ? headHeight
          : document.getElementById(headFatherId)?.querySelector(".ant-table-thead")?.offsetHeight;
    }else {
      tableTheadHeight = headHeight
          ? headHeight
          : document.querySelector(".ant-table-thead")?.offsetHeight;
    }
    // 避免没有页面无.ant-table-thead， 导致tableTheadHeight为undefined
    tableTheadHeight = tableTheadHeight ? tableTheadHeight : 0

    if (isHavePagination) {
      paginationHeight = (40 / 911) * clientHeight;
    }
    const otherHeight = otherHeightVal ? otherHeightVal : ((30 + 20) / 911) * clientHeight;
    // 页面整体长度- table上边到视窗上边的距离 - 表头 - 分页 - 其他
    tableHeight =
      clientHeight -
      tableBodyOffsetTop -
      tableTheadHeight -
      paginationHeight -
      otherHeight + 20;
  } catch (e) {
    console.log(e);
  }

  return tableHeight;
}

export function formatTableCol(formatFunc) {
  return ({ text }) => {
    return <span title={formatFunc(text)}>{formatFunc(text)}</span>;
  };
}
// 格式化标准时间
// export function formatTimeCol({ text }) {
//   return text ? moment(text).format("YYYY-MM-DD HH:MM:SS") : '';
// }

export function setColumnWidth(column, data, customerColumn) {
  const tempColumn = column;
  const tempWithds = new Object();
  if (customerColumn?.length) {
    customerColumn.forEach((item) => {
      tempWithds[item] = 0;
      data.forEach((el) => {
        tempWithds[item] =
          String(el[item]).replace(/[\u0391-\uFFE5]/g, "aa").length >
          tempWithds[item]
            ? String(el[item]).replace(/[\u0391-\uFFE5]/g, "aa").length
            : tempWithds[item];
      });
    });
  } else {
    column.forEach((item) => {
      tempWithds[item.dataIndex] = 0;
      data.forEach((el) => {
        tempWithds[item.dataIndex] =
          String(el[item.dataIndex]).replace(/[\u0391-\uFFE5]/g, "aa").length >
          tempWithds[item.dataIndex]
            ? String(el[item.dataIndex]).replace(/[\u0391-\uFFE5]/g, "aa")
                .length
            : tempWithds[item.dataIndex];
      });
    });
  }
  /*for (const key in tempWithds) {
    tempColumn.forEach((item) => {
      if (item.dataIndex === key) {
        item.title.replace(/[\u0391-\uFFE5]/g, "aa").length < tempWithds[key]
          ? (item.width = 8 * tempWithds[key])
          : (item.width = Math.max(8 * item.title.length, 60));
        return;
      }
    });
  }*/
  return tempColumn;
}

// 构建select筛选options: 字典options
export function dictionaryOptions(data) {
  if (!data || !data.length || data.length === 0) return []
  const options = data.map((item) => {
    return {
      ...item,
      label: item.dictValue,
      value: item.dictValue,
      key: item.dictId,
    };
  });
  return options;
}

export function dictionaryOptionsCode(data) {
  if (!data || !data.length || data.length === 0) return []
  const options = data.map((item) => {
    return {
      ...item,
      label: item.dictValue,
      value: item.dictCode,
      key: item.dictId,
    };
  });
  return options;
}

export function dictionaryOptionsId(data) {
  if (!data || !data.length || data.length === 0) return []
  const options = data.map((item) => {
    return {
      ...item,
      label: item.dictValue,
      value: item.dictId,
      key: item.dictId,
    };
  });
  return options;
}
// 构建select筛选一维options: 字典options
export function dictionaryOpt(data) {
  if (!data || !data.length || data.length === 0) return []
  const options = data.map((item,index) => {
    return {
      label: item,
      value: item,
      key: index,
    };
  });
  return options;
}

// 下拉列表过滤jtp的方法
export function handleJtpFilter(data) {
  const dataWithoutJtp = data.filter(item => {
    if(item.label) {
      return item.label !== 'J1' && item.label !== 'J2' && item.label !== 'J3' && item.label !== 'IN'
    }else {
      return item !== 'J1' && item !== 'J2' && item !== 'J3'   && item !== 'IN' 
    }
  })
  return dataWithoutJtp
}

export function formatCodeTerm(startDate="", endDate="") {
  let codeTerm = "";
  if(!startDate && !endDate) {
    codeTerm = ""
  }else {
    codeTerm = (startDate ? startDate : "") + "~" + (endDate ? endDate : "")
  }
  return codeTerm
}

// 显示菜单路径 消息通知使用
export function formatMenuName(firstName, secondName) {
  if(firstName) {
    if(secondName) {
      return `【${firstName}】-【${secondName}】`
    }else {
      return `【${firstName}】`
    }
  }else {
    return null
  }
}

export function getNoticeTargetPage(noticeInfo) {
  let locationUrl = ""
  switch (noticeInfo.relationStatus) {
    case "0":
      // 调令
      locationUrl = noticeInfo.firstMenu ==="page1" ? "/assignmentDetails" : "/consultantAssignment";
      break;
    case "1":
      // 需求
      locationUrl = noticeInfo.firstMenu === 'page1' ? "/matchingList" : "/consultantMatchingList"
      break;
    case "2":
      // code/tbd code
      locationUrl = "/assignment";
      break;
    case "5":
      // code/tbd code
      locationUrl = "/assignment";
      break;
    case "3":
      // account
      locationUrl = "/infoMaintain/account";
      break;
    case "4":
      // 任务管理
      if(noticeInfo.secondMenu ==="page24") {//团队管理
        locationUrl = "/benchTask/lmBenchTaskDetail";
      }else if(noticeInfo.secondMenu === "page23") {//任务看板
        locationUrl = "/benchTask/consultantbenchTaskDetail";
      }else {
        locationUrl = "/resource/task";
      }
      break;
    case "7":
      //座位管理
      if(noticeInfo.secondMenu ==="page62") {
        locationUrl = "/my/application";
      }else if(noticeInfo.secondMenu === "page61") {
        locationUrl = "/seat/approval";
      }else {
        locationUrl = "/my/seat";
      }
      break;
    case "8":
      // 项目管理
      locationUrl = "/infoMaintain/pm";
      break;
  }
  return locationUrl

}
