import request from "../../utils/http";

const sleep = (wait) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, wait);
  });

const mock = async (value) => {
  await sleep(500);
  return { data: { message: value } };
};

export function getUserInfo1(data) {
  mock("你好世界");
  return request.get("xxx", data);
}
