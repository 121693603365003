// import "default-passive-events";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission";
import Antd from "ant-design-vue";
import "../src/assets/style/reset.css";
import "../src/assets/style/public.css";
import "@/assets/iconfont/iconfont.css";
import "./style/index.less";
import Api from "./api/index";
import menus from "./directive/menus";
import permission from "./directive/permission";
import keyupSearch from "./directive/keyupSearch";
import checkOutside from "./directive/clickOutside";
import resize from "./directive/resize";
import loading from "./directive/loading";
import * as echarts from "echarts";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import SelectWithAll from "./components/SelectWithAll.vue";
import FilterLayout from "./components/FilterLayout.vue"
import mitt from "mitt";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

const app = createApp(App);

app.config.globalProperties.$api = Api;

app.config.productionTip = false;

app.use(store).use(router).use(Antd).use(ElementPlus).mount("#app");

app.use(PerfectScrollbar);

// 自定义指令
app.directive("auth", permission);

app.directive("menu", menus);

app.directive("check", checkOutside);

app.directive("resize", resize);

app.directive("loading", loading);

app.directive("keyupSearch", keyupSearch);

// 自定义组件
app.component("SelectWithAll", SelectWithAll);

app.component("FilterLayout", FilterLayout);

app.config.globalProperties.$EventBus = new mitt();

app.config.globalProperties.$echarts = echarts;

app.config.unwrapInjectedRef = true