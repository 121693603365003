import { createApp, nextTick } from "vue";
import { Spin } from "ant-design-vue";

const toggleLoading = (el, binding) => {
  if (binding.value) {
    el.spin.style.visibility = "visible";
    nextTick(() => {
      el.appendChild(el.spin);
    });
  } else {
    el.spin.style.visibility = "hidden";
  }
};

export default {
  mounted: function (el, binding) {
    const spin = createApp(Spin).mount(document.createElement("div"));
    el.instance = spin;
    el.spin = spin.$el;
    el.style.position = "relative";
    el.spin.style = `
      width: 100%; 
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ffffff66;
      position: absolute;
      top: 0;
      visibility: hidden;
      `;
    binding.value && toggleLoading(el, binding);
  },
  updated: function (el, binding) {
    if (binding.oldValue !== binding.value) {
      toggleLoading(el, binding);
    }
  },
};
