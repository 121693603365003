import request from "@/utils/http";

// 查询北森
export function getItalentReport(data) {
  return request.post(`/staffProjectDemands`, data);
}

// 同步北森
export function syncItalentReport(params) {
  return request.get(`/beisens/demands`, { params });
}

// 获取北森同步信息
export function getItalentMessage() {
  return request.get(`/staffProjectDemands/beisenTips`,);
}

// 获取北森报表类型
export function getItalentReportName() {
  return request.get(`/staffProjectDemands/beisenReportName`,);
}

//获取下拉框数据
export function getDropdownBoxData(params) {
  return request.get(`/staffProjectDemands/getDropdownBoxData`, params);
}

//导出北森信息
export function getItalentExport(data) {
  return request.post(`/staffProjectDemands/export`, data, {
    responseType: "blob",
  });
}
