import request from "@/utils/http";

// 获取员工信息列表
export function queryAllStaffInfo(params) {
  return request.get("/staffInfos/queryAllStaffInfo", { params });
}
// 获取员工信息列表
export function getStaffInfoList(params) {
  return request.get("/staffInfos", { params });
}

// 获取停用员工信息列表
export function getNotEnabledStaffInfoList(params) {
  return request.get("/staffInfos/notEnabled", { params });
}

//下载员工信息
export function downloadStaffInfoList() {
  return request.get("/staffInfos/downloadTemplate", { responseType: "blob" });
}
//导入员工信息
export function importStaffInfoList(data, params) {
  return request.post("/staffInfos", data, { params, loading: false });
}
//查询导入状态
export function getImportResult() {
  return request.get("/staffInfos/import/status", { loading: false });
}
//查询导入状态
export function getRPAStatus() {
  return request.get("/staffInfos/rpa/status");
}

//更新tag
export function updateStaffInfoTag(data) {
  return request.post("/staffInfos/updateTag", data);
}

//更新启用禁用
export function updateStaffInfoEnabled(data) {
  return request.post("/staffInfos/updateEnabled", data);
}

//查询变更记录列表数据
export function getHistoryList(data) {
  return request.post("/staffInfoChangeHistory/pageSearch", data);
}

//导出变更记录
export function historyExportList(data) {
  return request.post("/staffInfoChangeHistory/export", data, {
    responseType: "blob",
  });
}
