import request from "@/utils/http";

// 保存
export function saveSendmailDate(data) {
    return request.post("/todolists/saveSendmailDate", data);
}
// 创建默认模板
export function staffWelcomeLetterTemplatecreate(data) {
    return request.post("/staffWelcomeLetterTemplate/create/default", data);
}
// 更新模板
export function staffWelcomeLetterTemplateupdate(data) {
    return request.post("/staffWelcomeLetterTemplate/update", data);
}
//查询模板
export function getstaffWelcomeLetterTemplate(userId) {
    return request.get(`/staffWelcomeLetterTemplate?userId=${userId}`);
}
//查询修改模板信息
export function getstaffWelcomeLetterTemplateDefine(templateId,offering,userId) {
    return request.get(`/welcomeLetterCustomTemplate/query?templateId=${templateId}&offering=${offering}&userId=${userId}`);
}
//查询刷新修改信息
export function getWelcomeLetterCustomTemplateRefresh(templateId,offering,userId) {
    return request.get(`/welcomeLetterCustomTemplate/refresh?templateId=${templateId}&offering=${offering}&userId=${userId}`);
}
// 上传附件
export function staffWelcomeLetterTemplateUpload(data,params) {
    return request.post(`/staffWelcomeLetterTemplate/upload`, data, {
        params,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    }
    );
}
//下载附件
export function staffWelcomeLetterTemplateDownloadFile(id) {
    return request.post(`staffWelcomeLetterTemplate/downloadFile/${id}`);
}
//删除附件
export function staffWelcomeLetterTemplateDeleteFile(id) {
    return request.post(`/staffWelcomeLetterTemplate/deleteFile/${id}`);
}
//刷新模板
export function getRefreshTemplate(userId,isUpdata) {
    return request.get(`/staffWelcomeLetterTemplate/refresh?userId=${userId}&isUpdata=${isUpdata}`);
}
//其他模板保存
export function saveWelcomeLetterTemplateDeleteFile(data) {
    return request.post(`/staffWelcomeLetterTemplate/saveCustomTemplateData`,data);
}
//IDC更改
export function getIdcData(params) {
    return request.get(`/welcomeLetterCustomTemplate/idcData`,{params});
}
