import request from "@/utils/http";

//查询简历模板
export function getResumeTemplate(params) {
    return request.get(`/staffFile/getFile/cgChinaCvTemplate`, params);
}

//下载简历模板
export function getDownloadFiles() {
    return request.get(`/staffFile/downloadFile/cgChinaCvTemplate`, {
        responseType: "blob"
    });
}

//更新简历模板
export function postUploadFile(data, params){
    return request.post('/staffFile/uploadFile', data, {
        params,
        headers: {
            "Content-Type": "multipart/form-data",
          },
    })
}