<template>
  <div ref="filterContainerRef" class="filter-layout">
    <slot></slot>
    <slot name="customform">
    </slot>
    <div class="rms-filter-btn-container">
      <div class="operation-items">
        <span v-if="false" v-show="isShowCollapseBtn" @click="doCollapseEffect">
          <a-button type="link" class="mr12" @click="handleFilter">
            {{ show ? "展开" : "收起" }}
            <DownOutlined style="font-size: 10px" v-if="show" />
            <UpOutlined style="font-size: 10px" v-else />
          </a-button>
        </span>
        <Tooltip v-if="moreBtnVisible" title="更多筛选">
          <a-button type="primary" ghost @click.stop="moreForm" class="margin-r margin-none">
            <MoreOutlined />
          </a-button>
        </Tooltip>
        <a-button type="primary" ghost @click="resetForm" class="margin-r">
          <SyncOutlined />
        </a-button>
        <a-button type="primary" @click="searchForm" :class="isShowEmail === true ? 'margin-r' : ''" v-keyupSearch :disabled="isDisabled">
          <SearchOutlined />
        </a-button>
        <a-button v-if="isShowEmail" ghost type="primary" @click="sendEmailHandler">
           <MailOutlined/>发送邮件
        </a-button>
      </div>
    </div>
    <slot name="operationBtns"></slot>
  </div>
</template>

<script>
import { ref, onMounted, nextTick, getCurrentInstance } from "vue";
import {
  DownOutlined,
  SyncOutlined,
  UpOutlined,
  SearchOutlined,
  MoreOutlined,
  MailOutlined,
} from "@ant-design/icons-vue";
import Tooltip from '@/components/Tooltip'

export default {
  name: "FilterLayout",
  emits: ["doReset", "doSearch", "doCollapseEffect","showMoreForm","sendEmail"],
  props: {
    isShowMoreFlag: {
      type: Boolean,
      default: false
    },
    moreBtnVisible: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default:false
    },
    isShowEmail:{
      type: Boolean,
      default:false
    }
  },
  components: {
    Tooltip,
    DownOutlined,
    UpOutlined,
    SyncOutlined,
    SearchOutlined,
    MoreOutlined,
    MailOutlined
  },
  setup(props, { emit }) {
    const filterContainerRef = ref();
    const show = ref(false);

    // 初始化筛选项布局
    const initFilterLayout = async () => {
      await nextTick();
      show.value = false;
      handleLayout();
      handleSearchBtnStyle();
      handleCollapseBtn();
    };

    // 获取筛选项dom数组
    const getFilterList = () => {
      const antItemList =
        filterContainerRef.value?.getElementsByClassName("ant-form-item")
          ?.length > 0
          ? [
              ...filterContainerRef.value?.getElementsByClassName(
                "ant-form-item"
              ),
            ]
          : undefined;
      const rmsItemList =
        filterContainerRef.value?.getElementsByClassName("rms-form-item")
          ?.length > 0
          ? [
              ...filterContainerRef.value?.getElementsByClassName(
                "rms-form-item"
              ),
            ]
          : undefined;
      const itemList = antItemList || rmsItemList;
      return itemList;
    };

    const sendEmailHandler = () => {
      emit("sendEmail");
    }

    // 点击展开收起事件
    const handleFilter = () => {
      show.value = !show.value;
      handleLayout();
      handleSearchBtnStyle();
    };

    // 调整布局事件
    const handleLayout = () => {
      if (!getFilterList()) {
        throw new Error(
          "请使用<a-form-item></a-form-item>或class为rms-form-item的div包裹表单项！"
        );
      }
      getFilterList().forEach((item, index) => {
        if (!show.value) {
          item.style.display = "flex";
          return;
        }
        if (index > 3 && !item.className.includes("rms-filter-btn-container")) {
          item.style.display = "none";
        } else {
          item.style.display = "flex";
        }
      });
    };

    const isShowCollapseBtn = ref(false);
    // 判断是否显示展开收起按钮
    const handleCollapseBtn = () => {
      let r = false;
      // 筛选项大于等于8个就显示展开收起按钮
      if (isFinite(getFilterList()?.length) && getFilterList()?.length > 5) {
        r = true;
      }
      isShowCollapseBtn.value = r;
    };

    const { proxy } = getCurrentInstance();

    // 调整查询重置按钮位置
    const handleSearchBtnStyle = () => {
      const formcontainer = proxy.$el.getElementsByClassName("rms-form");
      if (!formcontainer || formcontainer.length < 1) {
        throw new Error("请使用class为rms-form的form表单！");
      }
      if (formcontainer.length > 1) {
        throw new Error("FilterLayout只能包裹一个form表单！");
      }
      const filterBtnList = proxy.$el.getElementsByClassName(
        "rms-filter-btn-container"
      );
      const filterBtnContainer = filterBtnList[0];
      formcontainer[0].append(filterBtnContainer);
    };

    // 查询
    const searchForm = () => {
      emit("doSearch");
    };

    // 点击展开收起的副操作
    const doCollapseEffect = () => {
      emit("doCollapseEffect");
    };

    // 重置
    const resetForm = () => {
      emit("doReset");
    };

    const flag = ref(false)
    const moreForm = () => {
      flag.value = !props.isShowMoreFlag
      emit("showMoreForm", flag.value);
    }
    const closeMoreForm = () => {
      emit("showMoreForm", false);
    }
    onMounted(() => {
      initFilterLayout();
    });

    return {
      moreForm,
      closeMoreForm,
      resetForm,
      searchForm,
      isShowCollapseBtn,
      show,
      handleFilter,
      filterContainerRef,
      handleSearchBtnStyle,
      doCollapseEffect,
      initFilterLayout,
      handleLayout,
      handleCollapseBtn,
      proxy,
      sendEmailHandler
    };
  },
};
</script>

<style lang="less" scoped>
.filter-layout{
  position: relative;
  display: flex;
  height: 76px;
  gap: 37px;
}
.rms-filter-btn-container {
  position: relative;
  width: 200px;
  .operation-items {
    position: absolute;
    bottom: 8px;
    display: flex;
    align-items: center;
  }
}

// form内布局
:deep(.ant-form-item) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0;
}

:deep(.ant-form-item-label) {
  width: 100%;
  text-align: left;
  font-family: "PingFang SC" ;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #2d3748;
  white-space: nowrap;
  padding: 0;

  label {
    width: 100%;
    text-align: left;
    word-wrap: break-word;
    word-break: break-word;
    display: inline-block;
    white-space: pre-wrap;
  }
}
:deep(.ant-form-item-control) {
  width: 100%;
}
:deep(.ant-input-affix-wrapper),
:deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector),
:deep(.ant-calendar-picker-input),
:deep(.ant-picker-range) {
  border: 1px solid #cccccc !important;
}

:deep(.ant-select-arrow) {
  color: #cccccc;
}
.margin-none{
  margin-left: 0;
}
.margin-r{
  margin-right: 12px;
}
:deep(.anticon-more){
  transform: rotate(90deg);
}
</style>
