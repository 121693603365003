const findDeactivated = (vnode) => {
    if(vnode.isDeactivated) {
        return true;
    } else {
        if(vnode.parent) {
            // 递归查找父级以上被deactivated的，如果有，就代表这个组件被deactivated
            return findDeactivated(vnode.parent)
        } else {
            return false;
        }
    }

}

export default {
    created: function (el, binding) {
        el.searchClickHandler = (keyEvent) => {
            if (keyEvent.code === 'Enter') {
                if (!findDeactivated(binding.instance._)) {
                    el.click();
                }
            }

        }
        window.addEventListener('keyup', el.searchClickHandler)
    },
    unmounted(el) {
        console.log(el, '注销指令')
        window.removeEventListener('keyup', el.searchClickHandler)
    }
};