import request from "@/utils/http";

// 筛选项字典
export function queryBaseData(params) {
  return request.post("/seat/queryBaseData", params);
}

// 座位统计
export function getSeatUsageInfo(params) {
  return request.post("/seat/getSeatUsageInfo", params);
}

// 所有座位使用list
export function queryUsedSeat(params) {
  return request.post("/seat/queryUsedSeat", params);
}
// 不可用座位详情信息
export function querySeatInfo(params) {
  return request.post("/seat/querySeatInfo", params);
}
// 查询在职人员
export function authorizedPerson(params) {
  return request.post("/AssignmentReview/authorizedPerson", params);
}
// 查询当前系统的groupLead
export function queryGroupLead(params) {
  return request.get("/seat/queryGroupLead", params);
}
// 上传审批凭证文件
export function seatUpload(params) {
  return request.post("/seat/upload", params);
}
// 上传审批凭证文件
export function seatApplication(params) {
  return request.post("/seat/seatApplication", params);
}
// 校验当前选择人员是否存在座位申请
export function checkUserSeatApplication(params) {
  return request.post("/seat/checkUserSeatApplication", params);
}
// 校验后查看座位申请单信息
export function queryRepeatSeatApplication(params) {
  return request.post("/seat/queryRepeatSeatApplication", params);
}

// 我的申请列表数据
export function selectApplication(data) {
    return request.post("/seat/selectApplication", data);
}

// 座位审批列表数据
export function selectApplicationAdmin(data) {
    return request.post("/seat/selectApplicationAdmin", data);
}

//groupLead
export function getGroupLeadData(params) {
    return request.get("/seat/groupLeanReturnByName", { params });
}

//申请详情
export function getApplicationInfo(params) {
    return request.get("/seat/selectApplicationInfo", { params });
}

//申请详情-下载
export function downloadSeatFile(fileId) {
    return request.get(`/seat/download/${fileId}`, { responseType: "blob" });
}

//我的申请-取消
export function cancelSeatApplication(data) {
    return request.post("/seat/cancelSeatApplication", data);
}

//座位审批-驳回
export function rejectSeatApplication(data){
    return request.post("/seat/rejectSeatApplication",data)
}
//座位审批-通过
export function seatPermissions(data){
    return request.post("/seat/seatPermissions",data)
}
// 获取座位统计详情报表接口
export function seatTableReport(data){
  return request.post("/seat/seatUsageReport",data)
}
// 获取座位使用率接口
export function seatGetTableRate(data){
  return request.post("/seat/seatMonthUsageReport",data)
}

// 员工出勤报告列表Table接口
export function seatGetAttendTable(data){
  return request.post("/AttendanceRecords/attendanceRecordsList",data)
}

//座位统计详情
export function seatUsageDetails(data){
    return request.post("/seat/seatUsageDetails",data)
}

//设置安全区域座位
export function setSafeZoneSeat(code){
    return request.get(`/seat/setSafeZoneSeat?code=${code}`)
}

//取消安全区域座位
export function cancelSafeZoneSeat(code){
    return request.get(`/seat/cancelSafeZoneSeat?code=${code}`,)
}

//座位统计-导出
export function exportSeatUsageReport(data){
    return request.post("/seat/exportSeatUsageReport",data,{ responseType: "blob" });
}

// 我的座位
export function selectPersonSeat(){
  return request.get("/seat/selectPersonSeat")
}

//员工出勤报告-导出
export function exportStaffReport(data){
    return request.post("/AttendanceRecords/export",data,{ responseType: "blob" });
}

// 员工出勤报告-上传
export function importSearReport(data){
  return request.post("/AttendanceRecords/import", data);
}

// 获取workLocation下拉
export function getWorkLocationOption(data){
  return request.post("/AttendanceRecords/getWorkLocation", data);
}

// 获取出勤统计报告
export function getAttendanceReport(data){
  return request.post("/AttendanceRecords/getGroupLeadAttendanceRecordsList", data);
}

// 获取出勤报告上传时间
export function getAttendanceReportTime(){
  return request.post("/AttendanceRecords/maxDate");
}

// 我的座位
export function selectPersonSeatByOffice(data){
    return request.post("/seat/selectPersonSeatByOffice", data);
}

// 座位统计-发送邮件
export function seatUsageSendEmail(data){
  return request.post("/seat/sentEmail", data);
}

// 座位管理-使用情况-出勤统计导出
export function seatAnalysisExport(data){
  return request.post("/AttendanceRecords/getGroupLeadAttendanceRecordsListExport", data,{ responseType: "blob" });
}

// 查询修改申请人
export function myApplycationChangeAppealPerson(params) {
  return request.get("/seat/findSeatRolePerson", { params, loading: false });
}

// 查询修改GroupLead
export function myApplycationChangeGroupLead(params) {
  return request.get("/seat/findGroupLeadPerson", { params, loading: false });
}

// 弹窗内修改信息
export function seatModalchangeInfo(data) {
  return request.post("/seat/updateApplicant", data);
}

// 校验我的审批按钮有无权限
export function seatVerifyMySeatButton(params) {
  return request.get("/seat/check", { params, loading: false });
}