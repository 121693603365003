import request from "@/utils/http";

export function getVerificationCodeEmail(params) {
  return request.get(`/login/email/code`, { params });
}
export function loginByEmail(data) {
  return request.post(`/login/email`, data);
}
export function getImgCode(params) {
  return request.get(`/login/imgCode`, { params });
}
export function logOut() {
  return request.get("/logout");
}
export function getAdminInformation(params) {
  return request.get(`/login/getAdminInformation`, { params });
}
// 获取系统升级公告
export function getAnnouncementsNotice() {
  return request.get("/staffannouncement/inProgressAnnouncements",{ loading: false });
}
export function noLoginByEmail(data){
  return request.post(`/login/noEmail`,data)
}
