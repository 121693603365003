import { debounce } from "lodash";

export default {
  mounted(el, binding) {
    el.resizeObserver = new window.ResizeObserver(
      debounce(
        (entries) => binding.value(entries[0]),
        Number(binding.arg) || 100
      )
    );
    el.resizeObserver.observe(el);
  },
  unmounted(el) {
    el.resizeObserver.disconnect();
  },
};
