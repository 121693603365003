import request from "@/utils/http";

// 根据登录人员CostCtr查询所属人员
export function getRoleMaintainList(data) {
  return request.post("/skills", data);
}

// 查询技能列表和星级
export function reviewSkillStars(params) {
  return request.get("/staffskillstars/reviewSkillStars", { params });
}
