<template>
  <div class="page-header" ref="headerRef">
    <div class="logo">
      <!-- 区分当前环境 -->
      <div v-if="currentEnv.flag" id="show-env">
        {{ currentEnv.name }}
      </div>
      <img @click="goHome" class="logo-img" src="@/assets/header/logo.png" alt="capgemini" />
    </div>
    <div class="welcome" v-show="false">
      <div>
        <div class="welcome-row">
          <span>你好</span>
          <span class="user-name-text">{{ user.fullName }}</span>
          <span>!</span>
        </div>
        <div class="welcome-row">
          <span>欢迎访问</span>
          <span class="sys-name-text">Resource Management System</span>
        </div>
      </div>
    </div>
    <div class="open-menu">
      <Menu class="menu"></Menu>
    </div>
    <div class="operation-btn" ref="operationBtnRef">
      <div class="sound-out-part" v-if="isUpdatingSystem">
        <a-divider
          type="vertical"
          style="
            font-size: 1.92917vw;
            border-left: 1.5px solid rgba(0, 0, 0, 0.06);
            margin: 0;
          "
        />
        <SoundOutlined class="sound-out" />
        <div class="scroll-wrap">
          <div class="updating-notice" :style="cssVars">
            {{ updatingMsg }}
          </div>
        </div>
      </div>
      <div class="userPhoto">
        <div @click="loginUserInfoPage">
          <a-avatar :span="62" :src="imgUrl" v-if="imgUrl"> </a-avatar>
          <img
            src="@/assets/menu/noImage.svg"
            v-else
            :class="['no-photo-img']"
            v-auth="['system:avatar:display']"
          />
        </div>
        <div>
          <div class="nameS">
            <a-tag
              style="
                margin-right: 0.11667vw;
                background: #fff;
                font-size: 0.865vw;
                padding: 0 0.16458vw;
              "
              >{{ $store.state.app.user.fullName }}</a-tag
            >
          </div>
          <div style="display: flex;align-items: center;">
            <a-tag
              color="blue"
              style="
                margin: 0 0.11667vw 0.1vw 0.21667vw;
                font-size: 0.61vw;
                line-height: 1.5715;
              "
              >{{ newswitchRole }}</a-tag
            >
            <a-popover
              :getPopupContainer="()=>$refs.operationBtnRef"
              placement="bottomRight"
              trigger="click"
              v-model:visible="show"
            >
              <template #title>
                <span
                  style="
                    color: #fafafa;
                    margin-left: 0.925vw;
                    font-size: 0.925vw;
                  "
                  >角色设置</span
                >
              </template>
              <template #content>
                <a-list
                  item-layout="horizontal"
                  :data-source="roleData"
                  size="small"
                >
                  <template #renderItem="{ item, index }">
                    <a-list-item>
                      <div>
                        <CheckOutlined
                          :style="`color: ${
                            item.role_name === newswitchRole
                              ? '#3182CE'
                              : '#fff'
                          };margin-right: 5px`"
                        />
                        <span>{{ item.role_name }}</span>
                      </div>
                      <a-tag
                        color="blue"
                        v-show="item.is_default_role === '0'"
                        style="
                          border: 0px solid #d9d9d9;
                          font-size: 0.625vw;
                          margin-left: 0.31vw;
                        "
                      >
                        默认
                      </a-tag>
                      <span style="float: right; margin-left: 14px">
                        <span
                          :class="`${
                            item.role_name === newswitchRole
                              ? 'operationLink disabled underline'
                              : 'operationLink underline'
                          }`"
                          @click="switchRole(index,item)"
                          style="margin-right: 5px"
                        v-auth="['system:role:switch']"
                        >
                          切换
                        </span>
                        <span
                          :class="`${
                            item.is_default_role === '0'
                              ? 'operationLink disabled underline'
                              : 'operationLink underline'
                          }`"
                          @click="finishSelectRole(index)"
                          v-auth="['system:role:default']"
                        >
                          设为默认
                        </span>
                      </span>
                    </a-list-item>
                  </template>
                </a-list>
              </template>
              <span @click="isShow" v-show="isShowRole">
                <UpOutlined
                  style="font-size: 0.59vw; color: #3182ce"
                  v-if="show"
                  v-auth="['system:role:drop']"
                />
                <DownOutlined
                  style="font-size: 0.59vw; color: #3182ce"
                  v-else
                  v-auth="['system:role:drop']"
                />
              </span>
            </a-popover>
          </div>
        </div>
      </div>
      <div>
        <a-tooltip placement="bottom">
          <template #title>
            <span>文件中转站</span>
          </template>
          <a-badge :dot="isHaveMessage">
           <CloudSyncOutlined class="operation-icon" @click="backFileList" />
          </a-badge>
        </a-tooltip>
      </div>
      <div class="imgIcon">
        <a-popover
          :getPopupContainer="()=>$refs.operationBtnRef"
          placement="bottomRight"
          overlayClassName="cus-popover help-popover"
        >
          <template #title>
            <span style="color: #fafafa">如需帮助，请联系</span>
          </template>
          <template #content>
            <div class="info">
              <span class="infos">{{ getAdmin?.fullName }}</span>
              <span class="xicon">|</span>
              <a :href='cnEmail' class="infos" style="text-decoration: underline">{{
                getAdmin?.email
              }}</a>
            </div>
          </template>
          <img src="@/assets/header/relation.png" alt="" />
        </a-popover>
      </div>
      <div class="rms-manual-popover">
        <a-popover
          :getPopupContainer="()=>$refs.operationBtnRef"
          placement="bottomRight"
          overlayClassName="cus-popover relation-overlap-popover"
          trigger="click"
          v-model:visible="showManualPop"
        >
          <template #title>
            <span style="color: #fafafa">RMS 说明手册</span>
          </template>
          <template #content>
            <a-list
                class="demo-loadmore-list"
                item-layout="horizontal"
                :data-source="rmsManualData"
            >
              <template #renderItem="{ item }">
                <a-list-item>
                  <span class="infoUser">
                      <img src="@/assets/header/FilePpt.png" alt="" v-if="
                            item?.fileName.indexOf('ppt') !== -1 ||
                            item?.fileName.indexOf('pptx') !== -1
                          " class="ppt"/>
                      <FilePdfOutlined
                          class="pdf"
                          v-else-if="item?.fileName.indexOf('pdf') !== -1"
                      />
                      <FileExcelOutlined
                          class="excel"
                          v-else-if="item?.fileName.indexOf('xls') !== -1 || item?.fileName.indexOf('xlsx') !== -1"
                      />
                      <FileWordOutlined class="word" v-else />
                      <a-tooltip class="tooltip-list" placement="topLeft" color="#fff">
                        <template #title>
                          <span style="color:#4D4D4D">{{item?.fileName}}</span>
                        </template>
                        <span
                            style="text-decoration: underline; cursor: pointer"
                            @click="preview(item)"
                            v-auth="['system:manual:preview']"
                        >{{ item?.fileName }}
                        </span>
                      </a-tooltip>
                    </span>
                  <span>
                  <span class="infoDate">{{
                      item?.operationTime
                    }}</span>
                  <a-tooltip class="tooltip-list" placement="top" color="#fff">
                    <template #title>
                      <span style="color:#4D4D4D">下载</span>
                    </template>
                    <DownloadOutlined style="color: #3182CE" @click="downloadManual(item)"/>
                  </a-tooltip>
                  <span>
                    <a-divider type="vertical" v-if="isShowManual"/>
                      <a-tooltip class="tooltip-list" placement="top" color="#fff">
                      <template #title>
                        <span style="color:#4D4D4D">删除</span>
                      </template>
                      <DeleteOutlined style="color: #DC5E4F" @click="deleteManual(item)" v-auth="['system:manual:delete']"/>
                    </a-tooltip>
                    </span>
                    </span>
                </a-list-item>
              </template>
            </a-list>
            <div v-if="isShowManual">
              <a-divider style="border-color: #f4f4f4; margin: 2px 0 8px 0" />
              <div style="text-align: right; padding: 0 1.5vw 0.5vw 0">
                <a-button
                  type="primary"
                  size="small"
                  @click="updataFile"
                  v-auth="['system:manual:update']"
                  >上传</a-button
                >
              </div>
            </div>
          </template>
          <QuestionCircleOutlined
            class="operation-icon"
            @click="isShowManualPop"
            v-auth="['system:manual:iconManual']"
          />
        </a-popover>
      </div>
      <div class="to-do-part">
        <a-tooltip placement="bottom">
          <template #title>
            <span>跳转到待办列表</span>
          </template>
          <a-badge :count="todoNum" v-show="isShowTodoList">
            <ProfileOutlined class="operation-icon" @click="backTodoList" />
          </a-badge>
        </a-tooltip>
      </div>
      <div class="msg-part" v-check="handleClickOutSide" ref="noticeTip">
        <div class="showInfo" v-if="showInfoList">
          <a-list item-layout="horizontal" :data-source="data" size="small">
            <template #header>
              <div class="notice">
                <div>通知</div>
                <span class="icon" @click="close"><CloseOutlined /></span>
              </div>
            </template>
            <template #renderItem="{ item, index }">
              <a-list-item @click="handleGoToDetail(item)">
                <a-list-item-meta>
                  <template #title>
                    <div class="noticeTitleContainer">
                      <div class="iconTitle">
                        <span
                          class="circle"
                          :style="`color: ${
                            item.noticeStatus === '0' ? '#DC5E4F' : '#C4C4C4'
                          };font-size: 20px;`"
                          >●</span
                        >
                        <span class="noticeTitle">{{ item.noticeTitle }}</span>
                      </div>
                      <div>
                          <span style="color: #ccc">{{
                              format(item.createTime)
                            }}</span>
                      </div>
                    </div>
                  </template>
                  <template #description>
                    <div class="noticeDetailList">
                      <a-popover
                        v-model:visible="item.popoverVisible"
                        :getPopupContainer="
                          (triggerNode) => {
                            return triggerNode.parentNode;
                          }
                        "
                        trigger="hover"
                      >
                        <template #content>
                          <div style="color: #999">{{ item.noticeDetail }}</div>
                        </template>
                        <div
                          @mouseenter="showPopover(index)"
                          class="noticeDetail noticeContent"
                        >
                          {{ item.noticeDetail }}
                        </div>
                        <div class="notice-read">
                          <span class="noticeContent">
                            {{
                              getMenuName(
                                item.firstMenuName,
                                item.secondMenuName
                              )
                            }}
                          </span>
                        </div>

                        <div class="read">
                          <span
                              class="link underline"
                              @click.stop="handleClick(item.id, index)"
                          >{{
                              item.noticeStatus === "0" ? "标记已读" : ""
                            }}</span
                          >
                        </div>
                      </a-popover>
                    </div>
                  </template>
                </a-list-item-meta>
              </a-list-item>
            </template>
            <template #footer>
              <div class="link underline more" @click="getmore">查看更多</div>
            </template>
          </a-list>
        </div>
        <a-badge :count="countNum">
          <BellOutlined class="operation-icon" @click="showInfoCount" />
        </a-badge>
      </div>
      <div class="imgIcon">
        <a-tooltip placement="bottom">
          <template #title>
            <span>退出登录</span>
          </template>
          <!--            <LogoutOutlined-->
          <!--                class="operation-icon"-->
          <!--                @click="handlerLoginout"-->
          <!--            />-->
          <span @click="handlerLoginout" v-auth="['system:login:quit']"
            ><img src="@/assets/header/log-out.png" alt=""
          /></span>
        </a-tooltip>
      </div>
    </div>
  </div>
  <div class="open-page" v-show="pageList.length > 0">
    <div class="tag" ref="tagRef">
      <a-tag
        v-for="(item, index) in pageList"
        :key="item.name"
        :class="activePage === item.name ? 'active-page-tag' : ''"
        :closable= "item.path !== '/dashboard'"
        @close="closePage(item,index)"
        @click="changePage(item)"
      >
        <template #closeIcon>
          <CloseOutlined />
          <span>|</span>
        </template>
        {{ item.pageName }}
      </a-tag>
    </div>
  </div>
  <div ref="demoModalRef">
  <a-modal v-model:visible="modalVisible" cancelText="取消" okText="确定" title="上传附件" width="29.2vw" :z-index="1000" centered
           @ok="handleUploadOk" @cancel="handleCloseModal" class="demo-modal" :getContainer="$refs.demoModalRef">
      <a-upload-dragger
          v-model:fileList="fileList"
          name="file"
          :multiple="true"
          accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx, .pdf"
          :beforeUpload="handleBeforeUpload"
          class="upload-file"
      >
        <p class="ant-upload-drag-icon">
          <UploadOutlined></UploadOutlined>
        </p>
        <p class="ant-upload-text">单击或拖动文件到此区域以上传</p>
        <p class="ant-upload-hint">
          仅支持上传doc,docx,ppt,pptx,pdf,xls,xlsx格式文件
        </p>
      </a-upload-dragger>
  </a-modal>
  <a-modal
    :getContainer="()=>$refs.headerRef"
    v-model:visible="previewVisible"
    title="预览"
    width="62.5vw"
    @cancel="closePreviewModal"
    centered
  >
    <template #footer>
      <div style="text-align: right">
        <a-button @click="closePreviewModal">取消</a-button>
        <a-button
          type="primary"
          @click="downloadRmsFile"
          v-auth="['system:manual:download']"
          style="margin-left: 10px"
          >下载</a-button
        >
      </div>
    </template>
    <iframe
      id="printIframe"
      :src="previewUrl"
      style="width: 100%; height: 70vh; border: none"
    ></iframe>
  </a-modal>
  </div>
  <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  computed,
  nextTick,
  watch,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { currentEnv } from "@/utils/showEnv";
import {
  BellOutlined,
  CloseOutlined,
  ProfileOutlined,
  SoundOutlined,
  QuestionCircleOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  UpOutlined,
  DownOutlined,
  CheckOutlined,
  DownloadOutlined,
  DeleteOutlined,
  FileExcelOutlined,
  UploadOutlined,
  CloudSyncOutlined,
} from "@ant-design/icons-vue";
import moment from "moment";
import { message } from "ant-design-vue";
import {
  downloadFile,
  formatMenuName,
  getNoticeTargetPage,
} from "@/utils/common";
import Menu from "@/layout/menu";
import ModalBox from '@/components/ModalBox'
import {debounce} from "lodash";

export default defineComponent({
  components: {
    BellOutlined,
    CloseOutlined,
    ProfileOutlined,
    SoundOutlined,
    FilePdfOutlined,
    FileWordOutlined,
    UpOutlined,
    DownOutlined,
    CheckOutlined,
    Menu,
    QuestionCircleOutlined,
    DownloadOutlined,
    DeleteOutlined,
    FileExcelOutlined,
    ModalBox,
    UploadOutlined,
    CloudSyncOutlined,
  },
  emits: ["change-page-list"],
  setup(props, ctx) {
    const data = ref([]);
    const { $api } = useGlobalPropertyHook();
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const goHome = ()=> {
      if(store.state.app.defaultRole==='RM'){
        router.push("/dashboard");
      }else{
        router.push("/home");
      }
    }
    const tagRef = ref();

    // const isFirst = ref(true);
    const isFirst = computed(() => store.state.app.isFirst);
    const state = reactive({
      file: {},
    });
    const showInfoList = ref(false);
    const countNum = computed(() => store.state.app.countNum);
    const todoNum = computed(() => store.state.app.todoNum);
    const isTrunWindow = ref(false);
    const showNoticeDetail = ref("");
    let user = ref();
    const portrait = computed(() => store.state.app.portrait);
    user.value = store.state.app.user;

    const getPhotoUrl = () => {
      $api.queryMyPhoto().then((res) => {
        const photoUrl = res.data || "";
        store.commit("SET_PORTIAIT", photoUrl);
      });
    };

    const getCountNum = () => {
      store.dispatch("getCountNum");
      $api.getNoticeInfo(0, 1, 3).then((res) => {
        data.value = res.data.resource.map((item) => {
          return {
            ...item,
            title: item.noticeType,
            noticeTitle: item.noticeTitle,
            noticeDetail: item.noticeDetail,
            createTime: item.createTime,
            popoverVisible: false,
          };
        });
        data.value.length > 3 && data.value.splice(0, 2);
      });
    };

    const getTodoAllNum = () => {
      store.dispatch("getTodoAllNum");
    };
    const isShowTodoList = ref(false);
    const menusPermission = computed(() => store.state.app.menus);
    //获取当前角色和邮箱
    const role = computed(() => localStorage.getItem("switchRoleName"));
    const email = computed(() => store.state.app.user.email);
    watch(
      () => store.state.app.menus,
      () => {
        if (menusPermission.value.indexOf("system:pending") !== -1) {
          //RM Leader只有jiang tingting才能配置待办列表，
          isShowTodoList.value = role.value !== "RM Leader"?true:email.value==="tingting.jiang@capgemini.com";
        } else {
          isShowTodoList.value = false;
        }
      }
    );

    let announcementTimer;

    onMounted(() => {
      getPhotoUrl();
      nextTick(() => {
        getCountNum();
        // 有待办事项图标权限才调这个方法
        if (menusPermission.value.indexOf("system:pending") !== -1) {
          isShowTodoList.value = role.value !== "RM Leader"?true:email.value==="tingting.jiang@capgemini.com";
          getTodoAllNum();
        }
        store.commit("SET_ISUPDATING", false);
        getUpgradeAnnouncement();
        getFileMark();
      });
    });

    announcementTimer = setInterval(() => {
      getUpgradeAnnouncement();
      getFileMark();
    }, 1000 * 60 * 5);

    const getUpgradeAnnouncement = async () => {
      store.dispatch("updateUpgradeAnnouncement");
    };

    let fileMarkTimer = setInterval(() => {
      getFileMark();
    }, 1000 * 60);

    //文件中转站红点标记
    const getFileMark = () => {
      store.dispatch("getIsFileMessage");
    };

    const updatingMsg = computed(() => store.state.app.updatingMsg);

    const isUpdatingSystem = computed(() => store.state.app.isUpdating);

    const scrollTime = computed(
      () => updatingMsg.value.length / (100 / 15) + "s"
    );
    const cssVars = ref({
      "--time": scrollTime,
    });

    onBeforeUnmount(() => {
      clearInterval(announcementTimer);
      clearInterval(fileMarkTimer);
    });
    const modelValue = ref(false)
    const content = ref('')
    const showIconCom = ref('')
    const flag = ref()
    const modalManualId = ref('')
    const handleOk = () => {
      if(flag.value === '1') {
        $api.logOut().then(() => {
          sessionStorage.clear();
          store.commit("RESET_APP");
          router.push("/login");
        });
      } else {
        $api.delManualFile(modalManualId.value).then((res) => {
          message.success(res.msg);
        });
      }
    }
    const handlerLoginout = () => {
      console.log(123)
      flag.value = '1'
      content.value = '确定要退出登录吗？'
      showIconCom.value = 'ExclamationCircleOutlined'
      modelValue.value = true
      /*Modal.confirm({
        title: () => "提示",
        content: "确定要退出登录吗？",
        width: "20%",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确定",
        cancelText: "取消",
        centered: true,
        onOk() {
          $api.logOut().then(() => {
            sessionStorage.clear();
            store.commit("RESET_APP");
            router.push("/login");
          });
        },
      });*/
    };
    const goUserInfo = () => {
      router.push("/userInfo");
    };
    const backHome = (e) => {
      if (e == 2) {
        isTrunWindow.value = true;
      } else {
        isTrunWindow.value = false;
        router.push("/home");
      }
    };

    const trunNewWindow = () => {
      sessionStorage.setItem("store", JSON.stringify(store.state));
      isTrunWindow.value = false;
      window.open("/home");
    };

    const showInfoCount = () => {
      getCountNum();
      showInfoList.value = true;
    };

    const handleClickOutSide = () => {
      showInfoList.value = false;
    };

    const close = () => {
      showInfoList.value = false;
    };

    // 确保每次点击查看更多按钮都重新查询消息通知(即使已经在消息通知页面)
    let flagNum = 1;
    const getmore = () => {
      showInfoList.value = false;
      router.push({
        path: "/notice",
        query: { pageFlag: flagNum++ },
      });
    };

    const format = (time) => {
      return moment(time).format("YYYY.MM.DD HH:mm:ss");
    };

    const showTitleColor = (title) => {
      switch (title) {
        case "Matching":
          return "trunGreen";
        case "Assignment":
          return "trunBlue";
        case "Release":
          return "trunRed";
        case "Bench":
          return "trunYellow";
        default:
          break;
      }
    };

    const avatarColor = (title) => {
      switch (title) {
        case "Matching":
          return "Green";
        case "Assignment":
          return "Blue";
        case "Release":
          return "Red";
        case "Bench":
          return "Yellow";
        default:
          break;
      }
    };

    const showPopover = (index) => {
      // console.log("0showPopover");
      data.value[index].popoverVisible = true;
    };

    const backTodoList = () => {
      getTodoAllNum();
      router.push({ path: "/todoList" });
    };

    const pageList = ref([]);
    const activePage = ref("");
    let isToHome = false;
    const closePage = (item,index) => {
      const activeName = pageList.value[index].name;
      //关闭座位申请页面，清除  座位申请操作状态（新建，1修改，2延期）
      pageList.value[index].name === 'seatApplication'?sessionStorage.removeItem('actionFlag'):''
      pageList.value.splice(index, 1);
      ctx.emit("change-page-list", pageList.value);

      if (activePage.value === activeName) {
        if (pageList.value.length === 0) {
          isToHome = true;
          const roles = store.state.app.user.roles;
          if (
              (roles.length === 2 &&
                  roles.indexOf("4") !== -1 &&
                  roles.indexOf("5") !== -1) ||
              (roles.length === 1 &&
                  (roles.indexOf("4") !== -1 || roles.indexOf("5") !== -1))
          ) {
            router.push({path: "/userInfo"});
          } else {
            router.push("/home");
          }
          return;
        }
        let path = "";
        // 最后一个tag
        if (index === pageList.value.length) {
          path = pageList.value[pageList.value.length - 1].path;
        } else {
          path = pageList.value[index].path;
        }
        router.push({path});
      }
    };
    const changePage = (item) => {
      if (activePage.value !== item.name) {
        router.push({ path: item.path });
      }
    };
    const handleClick = (id, index) => {
      $api.readSingleNoticeDone([{ id }]).then(() => {
        message.success("已读");
        data.value[index].noticeStatus = "1";
        store.dispatch("getCountNum");
      });
    };
    // 获取并格式化菜单名称
    const getMenuName = (firstName, secondName) => {
      return formatMenuName(firstName, secondName);
    };
    const handleGoToDetail = (noticeInfo) => {
      // 批量不跳转
      if (noticeInfo.relationStatus == "6") return;
      const { relationStatus, firstMenu, secondMenu } = noticeInfo;
      // 如果没有一二级菜单则是旧数据，不需要跳转
      if (!firstMenu || !secondMenu) return;
      const info = Object.assign({}, { relationStatus, firstMenu, secondMenu });
      router.push({
        path: getNoticeTargetPage(info),
        query: { id: noticeInfo.relationId },
      });
      close();
    };

    const getAdmin = computed(() =>
      JSON.parse(localStorage.getItem("adminRelation"))
    );
    const cnEmail = computed(() => 'mailto:' + getAdmin.value?.email)

    const showManualPop = ref(false);
    const isShowManualPop = () => {
      showManualPop.value = !showManualPop.value;
      $api.queryManual().then((res) => {
        if(res.data){
          rmsManualData.value = res.data.map((item) => {
            return {
              id: item.id,
              relationId: item.relationId,
              relationFileType: item.relationFileType,
              fileName: item.fileName,
              createTime: item.createTime,
              operationTime: moment(item.operationTime).format("YYYY-MM-DD"),
            };
          });
        }
      });
    };
    const isShowManual = computed(() => {
      const roles = store.state.app.user.roles
        ? store.state.app.user.roles
        : [];
      return roles.indexOf("1") !== -1 || roles.indexOf("6") !== -1;
    });
    const modalVisible = ref(false);
    const updataFile = () => {
      showManualPop.value = false;
      modalVisible.value = true;
    };
    const handleCloseModal = () => {
      fileList.value = []
      modalVisible.value = false;
    };
    const deleteManual = (item) => {
      flag.value = '2'
      modalManualId.value = item.id
      content.value = '是否确定删除？'
      showIconCom.value = 'ExclamationCircleOutlined'
      modelValue.value = true
    }
    const downloadManual = (item) => {
      $api.downloadManualFile(item.id).then((res) => {
        downloadFile(res.file, item.fileName);
      });
    }

    const fileList = ref([])
    let messageTimer = null;
    const handleBeforeUpload = (file) => {
      return new Promise(() => {
        for (let i = 0; i < fileList.value.length; i++) {
          if (fileList.value[i]) {
            if (fileList.value[i].name === file.name) {
              // settimeout 防止弹出多个消息提示
              clearTimeout(messageTimer);
              messageTimer = setTimeout(() => {
                message.error("请不要选择重复的文件！");
              });
              return false;
            }
          }
        }

        if((file.name.split('.').length - 1) > 1){
          message.error("文件名不能包含下列任何字符：. \\ / * ? < > |！");
          return false;
        }

        if (file.size === 0) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error("文件为空文件，请检查后再次上传!");
          });
          return false;
        }
        const isLt50M = file.size / 1024 / 1024 < 50;
        if (!isLt50M) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error("文件大小限制50MB，请检查后再次上传!");
          });
          return false;
        }

        if (!file.type.includes("application/msword") &&
            !file.type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document") &&
            !file.type.includes("application/vnd.ms-powerpoint") &&
            !file.type.includes("application/vnd.openxmlformats-officedocument.presentationml.presentation") &&
            !file.type.includes("application/vnd.ms-excel") &&
            !file.type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") &&
            !file.type.includes("application/pdf")
        ) {
          clearTimeout(messageTimer);
          messageTimer = setTimeout(() => {
            message.error("只可以上传doc,docx,ppt,pptx,xls,xlsx格式的文件！");
          });
          return false;
        }
        fileList.value = [...fileList.value, file];
      })
    };
    const handleUploadOk = debounce(() => {
      const formData = new FormData();
      fileList.value.forEach((item) => {
        formData.append("file", item);
      });
      $api.updateManual(formData).then(res => {
        if (res.code === 200) {
          fileList.value = []
          modalVisible.value = false;
          message.success("上传成功！")
        }
      })
    }, 1000);

    //预览
    const previewVisible = ref(false);
    const previewUrl = ref("");
    const previewManualData = ref({
      previewId : '',
      previewName: '',
    })

    const preview = async (item) => {
      showManualPop.value = false;
      previewManualData.value.previewId = item.id
      previewManualData.value.previewName = item.fileName
      const { data } = await $api.previewManual(item.id);
      previewVisible.value = true;
      if (data.indexOf("pdf") !== -1) {
        previewUrl.value = data;
      } else {
        previewUrl.value = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(data)}`;
      }
    };

    const closePreviewModal = () => {
      previewVisible.value = false;
      previewUrl.value = "";
    };
    const downloadRmsFile = () => {
      $api.downloadManualFile(previewManualData.value.previewId).then((res) => {
        downloadFile(res.file, previewManualData.value.previewName);
      });
    };

    const rmsManualData = ref([])
    const imgUrl = computed(() => store.state.app.portrait);
    const newswitchRole = computed(() => store.state.app.defaultRole? store.state.app.defaultRole : localStorage.getItem("switchRoleName"));
    const show = ref(false);
    const isShow = () => {
      show.value = !show.value;
      //if(show.value === true) handlerSwitchRole()
    };
    const isShowRole = ref(false);

    const roleData = ref([]);
    const handlerSwitchRole = () => {
      $api.getRole().then((res) => {
        roleData.value = res.data;
        if (roleData.value.length > 1) {
          isShowRole.value = true;
        }
      });
      //isSelectRole.value = false
      // roleIdData.value = ''
    };

    const loginUserInfoPage = () => {
      router.push("/userInfo");
    };
    const switchRole = async (index,item) => {
      //重新判断系统拿到的角色
      let user = ref();
      user.value = store.state.app.user;
      user.value.roles = [item.role_id];
      store.commit("SET_USERINFO", user.value);
      localStorage.setItem("switchRoleName", item.role_name);
      localStorage.setItem("switchRole", item.role_id);
      newswitchRole.value =item.role_name;
      store.commit("SET_DEFAULT_ROLE", item.role_name);
      localStorage.setItem("switchRoleName", newswitchRole.value);
      const params = {
        roleId: item.role_id,
      };
      await $api.getPermsByRole(params).then((res) => {
        const { btn, menu, router } = res.data;
        store.commit("SET_PERMS", btn);
        store.commit("SET_MENUS", menu);
        store.commit("SET_PAGES", router);
        localStorage.setItem("accessPerms", btn)
        localStorage.setItem("accessMenus", menu)
        localStorage.setItem("accessPages", router)
      });
      await $api.permsByRoleId(params).then((res) => {
        if (res.code === 200) {
          store.commit("SET_MENUSLIST", res.data);
          const menus = {menusList: res.data}
          localStorage.setItem("menusList", JSON.stringify(menus))
        }
      });
      await $api.getNoticeNum().then((res) => {
        store.commit("SET_COUNTNUM", res.data);
      });
      if(item.role_name === 'RM') {
        await router.push({ path: "/dashboard", query: { id: "0" } })
      } else {
        await router.push({ path: "/home", query: { id: "0" } });
      }
      await location.reload();
    };
    const finishSelectRole = (index) => {
      const params = {
        roleId: roleData.value[index].role_id,
      };
      $api.updateDefaultRole(params).then((res) => {
        if (res.code === 200) {
          message.success("修改成功");
          //isSelectRole.value = false
          handlerSwitchRole();
        }
      });
    };

    //文件中转站
    const isHaveMessage = computed(() => store.state.app.isFileMessage);
    const backFileList = () => {
      router.push({ path: "/downloadtask" });
    };

    onMounted(() => {
      handlerSwitchRole();
    });

    watch(
      route,
      (to, from) => {
        if (route.query.id === "0") {
          //角色切换时跳转回首页清空其他页面(RM-跳转dashboard)
          // if(newswitchRole.value === 'RM') {
          //   router.push("/dashboard")
          // } else {
            pageList.value = [];
            localStorage.removeItem("switchRoleGoHome");
          //}
        }
        if (
          (from && !isToHome) ||
          (to.name !== "home" && to.name !== "userInfo")
        ) {
          isToHome = false;
          //RM-保留dashboard
          if(newswitchRole.value === 'RM' && !pageList.value.map(item => item.name).includes('Dashboard')){
            const item = {
              name: 'Dashboard',
              path: '/dashboard',
              pageName: 'Dashboard',
              keepAlive: '',
            };
            pageList.value.push(item);
          }
          // isFirst.value = false
          // 页面跳转时，如果原来没有就增加一个tab页
          const tabIndex = pageList.value.findIndex((item) => {
            return item.name === to.name;
          });
          if (tabIndex === -1) {
            //用于实现需求匹配和新建匹配只有一个tab页
            console.log(to.meta.name)
            const matchIndex = pageList.value.findIndex((item) => {
              return item.pageName === to.meta.name;
            });
            if(matchIndex !== -1) {
              pageList.value.splice(matchIndex,1);
            }
            const item = {
              name: to.name,
              path: to.path,
              pageName: to.meta.name,
              keepAlive: to.meta.keepAlive,
            };
            pageList.value.push(item);
            ctx.emit("change-page-list", pageList.value);
            nextTick(() => {
              if (
                tagRef.value &&
                tagRef.value.scrollWidth &&
                tagRef.value.offsetWidth
              ) {
                tagRef.value.scrollLeft =
                  tagRef.value.scrollWidth - tagRef.value.offsetWidth;
              }
            });
          }
          activePage.value = to.name;
        } else if(newswitchRole.value === 'RM') {
          router.push("/dashboard")
        }
      },
      { immediate: true }
    );

    return {
      goHome,
      user,
      showInfoList,
      countNum,
      todoNum,
      ...toRefs(state),
      handlerLoginout,
      goUserInfo,
      portrait,
      backHome,
      isTrunWindow,
      trunNewWindow,
      showInfoCount,
      data,
      handleClickOutSide,
      close,
      getmore,
      getCountNum,
      format,
      showTitleColor,
      avatarColor,
      showPopover,
      showNoticeDetail,
      backTodoList,
      isFirst,
      pageList,
      activePage,
      closePage,
      changePage,
      tagRef,
      handleClick,
      getTodoAllNum,
      currentEnv,
      getMenuName,
      handleGoToDetail,
      isUpdatingSystem,
      updatingMsg,
      scrollTime,
      cssVars,
      isShowTodoList,
      menusPermission,
      isShowManual,
      modalVisible,
      updataFile,
      handleCloseModal,
      preview,
      closePreviewModal,
      previewUrl,
      previewVisible,
      showManualPop,
      isShowManualPop,
      downloadRmsFile,
      rmsManualData,
      getAdmin,
      imgUrl,
      newswitchRole,
      show,
      isShow,
      isShowRole,
      roleData,
      handlerSwitchRole,
      loginUserInfoPage,
      switchRole,
      finishSelectRole,
      modelValue,
      content,
      showIconCom,
      handleOk,
      cnEmail,
      deleteManual,
      downloadManual,
      fileList,
      handleBeforeUpload,
      handleUploadOk,
      previewManualData,
      modalManualId,
      role,
      email,
      backFileList,
      isHaveMessage,
      getFileMark,
    };
  },
});
</script>
<style lang="less" scoped>
@import "~@/style/aModal.less";
.page-header {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 5px 14px rgba(24, 144, 255, 0.05);
  display: flex;
  height: 60px;

  .logo {
    // 宽度和菜单相同
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1vw;
    margin-right: 2vw;

    // 当前环境样式
    #show-env {
      min-width: 70px;
      margin-left: 8px;
      height: 15px;
      font-size: 13px;
      color: red;
      position: absolute;
      left: 0;
      top: 30px;
    }
    .logo-img {
      height: 43px;
      width: 172px;
      margin-top: 11px;
      cursor: pointer;
    }
  }

  .welcome {
    flex: 1;
    display: flex;
    align-items: center;

    .welcome-row {
      font-family: "PingFang SC";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      color: rgba(49, 130, 206, 0.5);

      .user-name-text {
        color: #2d3748;
        padding: 0 5px;
      }

      .sys-name-text {
        color: #3182ce;
        font-size: 18px;
        padding-left: 12px;
      }
    }
  }

  .open-menu {
    //flex: 1;
    //flex-grow: 1;
    width: calc(100% - 1000px);
    //display: flex;
    //align-items: center;
  }

  .operation-btn {
    width: 750px;
    padding-right: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .sound-out-part {
      display: flex;
      flex-direction: row;
      flex: 1;
      align-items: center;
      flex-wrap: nowrap;
      margin-right: 15px;

      .sound-out {
        animation: zoom 1s infinite;
        transition: 1s ease 0s;
        ::v-deep svg {
          width: 20px;
          height: 20px;
        }
        color: #3182ce;
        margin-left: 20px;
        margin-right: 5px;
      }

      .scroll-wrap {
        width: 10vw;
        height: 60px;
        display: flex;
        align-items: center;
        overflow: hidden;
        position: relative;

        .updating-notice {
          position: absolute;
          white-space: nowrap;
          font-size: 14px;
          animation: scroll var(--time) linear infinite;
          /* Safari 和 Chrome */
          -webkit-animation: scroll var(--time) linear infinite;
        }
      }
    }

    @keyframes scroll {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }

    @keyframes zoom {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }

    .operation-icon {
      ::v-deep svg {
        width: 30px;
        height: 30px;
      }

      color: #3182ce;
      margin-left: 20px;
    }
    .imgIcon {
      img {
        width: 30px;
        height: 30px;
        color: #3182ce;
        margin-left: 20px;
        vertical-align: inherit !important;
        cursor: pointer;
      }
    }
    .userPhoto {
      display: flex;
      align-items: center;
      .ant-avatar {
        width: 48px !important;
        height: 48px !important;
        margin-right: 5px;
        cursor: pointer;
      }
      .no-photo-img {
        width: 40px;
        cursor: pointer;
        &.no-photo-img-collapsed {
          width: 40px;
        }
      }
      .nameS {
        font-size: 15px;
      }
      .ant-tag {
        border: 0px solid #d9d9d9;
      }
    }
  }
}
.open-page {
  //flex: 1;
  //flex-grow: 1;
  height: 40px;
  display: flex;
  align-items: center;
  background: var(--unnamed, #fafafa);
  //box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.08);
  //margin-left: 10px;
  border-bottom: 0.10417vw solid rgba(0, 0, 0, 0.08);

  .tag {
    height: 100%;
    //padding: 10px 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: auto;
    margin-left: 10px;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      width: 5px;
      height: 5px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }

  .ant-tag {
    background: #f7fafc;
    padding: 8px 60px 5px 12px;
    color: #303133;
    position: relative;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    cursor: pointer;
    border: 0 solid #d9d9d9;

    :deep(.ant-tag-close-icon) {
      position: absolute;
      right: 0;
      top: 5px;
      color: #9d9d9d;

      svg {
        width: 12px;
        height: 12px;
      }
      span {
        margin-left: 10px;
        font-size: 20px;
        font-weight: 200;
        color: #cccccc;
      }
    }
  }

  .active-page-tag {
    background: #ecf5fe;
    border-top: 0.10417vw solid #3182ce;
    font-size: 15px;
  }
}
#header {
  width: 100%;
  height: 75px;
  background: #fff;
  color: rgb(69 47 186 / 85%);
  margin-bottom: 5px;
  position: relative;

  .header-title {
    display: flex;
    position: absolute;
    top: 20px;
    right: 100px;

    > div:nth-of-type(1) {
      margin-right: 40px;
    }

    > div:nth-of-type(2) {
      margin-right: 10px;
    }

    > div:nth-of-type(3) {
      margin-top: 5px;
    }
  }
}

.back-home {
  float: left;
  margin: 20px 40px;

  .anticon + .anticon {
    margin-left: 20px;
  }
}

.showInfo {
  position: absolute;
  z-index: 10;
  background: #fff;
  right: 0;
  margin-right: 30px;
  margin-top: 60px;
  width: 30.8vw;
}

.more {
  font-size: 16px;
  margin: 0 auto;
}

:deep(.ant-list) {
  .ant-list-header {
    padding: 16px 24px;
    height: 58px;
    background-color: #fbfbfb;
    border-bottom: none;
  }
  .ant-list-item {
    padding: 14px 24px;
    &:hover {
      background: #e9f6fe;
    }
  }
  .ant-list-footer {
    padding: 22px;
    text-align: center;
  }
}

.notice {
  font-size: 16px;
  display: block;
  // text-align: center;
  .icon {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 58px;
    height: 58px;
    text-align: center;
    line-height: 58px;
    cursor: pointer;
  }
}

.noticeTitle {
  margin-left: 8px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}
.noticeTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .iconTitle {
    display: flex;
    align-items: center;
  }
}

/* :deep(.ant-list-item-meta-description) {
  height: 24px;
} */

.noticeDetailList {
  /*  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
}

.trunGreen {
  color: #b4cf98;
}

.trunBlue {
  color: #80b3e2;
}

.trunRed {
  color: #df8484;
}

.trunYellow {
  color: #fcd64f;
}

.Green {
  background: #b4cf98;
}

.Blue {
  background: #80b3e2;
}

.Red {
  background: #df8484;
}

.Yellow {
  background: #fcd64f;
}
.noticeContent {
  font-weight: 500;
  font-size: 18px;
  color: #999999;
}

.noticeDetail {
  //cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 20px;
  line-height: 150%;
}

.notice-read {
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
}
.read{
  text-align: right;
}
.demo-loadmore-list {
  max-height: 14vw;
  overflow: auto;
  :deep(.ant-list-item-action){
    margin-left: 1vw;
  }
  :deep(.anticon) {
    font-size: 0.9vw;
  }
}
.upload-file {
  :deep(.ant-upload-list) {
    max-height: 6.2vw;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      width: 5px;
      height: 5px;
      background: #d0e8ff;
      border: 1px solid #d0e8ff;
      border-radius: 5px;
    }
  }
  :deep(.ant-upload-list-item) {
    height: auto;
  }
}
</style>
<style lang="less" scoped>
:deep(.ant-popover){
  //width: 15.3vw;
}
:deep(.ant-popover-title) {
  background-color: #3182ce;
  padding: 12px 10px 12px 20px;
}
:deep(.ant-popover-inner-content) {
  padding: 0;
  .info {
    padding: 16px 24px;
    .infos {
      font-size: 16px;
      color: #3182ce;
      margin: 0 6px 0 0;
    }
    .xicon {
      font-weight: 700;
      color: #3182ce;
      font-size: 16px;
      margin: 0 6px 0 0;
    }
  }
  .pdf {
    color: #DC5E4F;
    margin-right: 5px;
  }
  .word {
    color: #3182CE;
    margin-right: 5px;
  }
  .excel {
    color: #58B99E;
    margin-right: 5px;
  }
  .ppt {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  .infoDate {
    color: #999999;
    margin-right: 1vw;
  }
  .infoUser {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
:deep(.ant-popover-arrow){
  border-top-color: #3182ce !important;
  border-left-color: #3182ce !important;
}
:deep(.ant-btn-sm) {
  height: 1.55vw;
}
:deep(.cus-popover){
  width: 18vw;
}
:deep(.help-popover){
  width: 18vw;
}
:deep(.relation-overlap-popover){
  width: 23vw;
}
.doc-list{
  padding: 16px 24px;
}
:deep(.ant-badge-dot){
  width: 10px;
  height: 10px;
}
</style>
