const app = {
  state: {
    assignmentCondition: {},
  },
  mutations: {
    SET_ASSIGNMENT_CONDITION: (state, assignmentCondition) => {
      state.assignmentCondition = assignmentCondition;
    },
  },
  actions: {},
};
export default app;
