import request from "@/utils/http";

export function getAssignmentSearchList(data) {
  return request.post(`/assignmentInfo`, data);
}

// 通过类型查询字典
export function subServiceLineComboBox(params) {
  return request.get(`/staffDictionary/subServiceLineComboBox`, { params });
}

// 通过code进行字典筛选
export function getDictionaryByCode(data) {
  return request.post(`/staffDictionary/condition`, data);
}

// 通过serviceLine获取subServiceLine
export function getSubServiceLine(data) {
  return request.post(`/staffDictionary/subServiceLine`, data);
}

//查询assignment详细信息
export function getAssignmentDetails(data) {
  return request.post(`/assignmentInfo/infos`, data);
}

// 查询assignment信息和评价信息的接口名
export function getAppraises(params) {
  return request.get(`/assignmentInfo/reviews`, { params });
}

// 免登录查询assignment信息和评价信息的接口名
export function nonLogingetAppraises(serialNo,params) {
  return request.get(`/singleSignOn/reviews?serialNo=${serialNo}`, { params });
}

// pm评价
export function pmAppraises(data) {
  return request.post(`/assignmentInfo/appraises`, data);
}

// 未登录评价
export function nonLoginpmAppraises(data) {
  return request.post(`/singleSignOn/appraises?serialNo`, data);
}

// RM release（hardbook）
export function rmSpecialRelease(data) {
  return request.post(`/assigns/special`, data);
}

//修改信息
export function sumbitInfo(data) {
  return request.post(`/assignmentInfo/assignments`, data);
}

//PM 直接确定上项目时间
export function PMConfirm(params) {
  return request.post(`/assigns/confirm`, {}, { params });
}

//查询PM申请
export function getPMAssApply(params) {
  return request.get(`/assigns/queryApply`, { params });
}

// PM 上项目申请
export function pmAssApply(data) {
  return request.post(`/assigns/pmApply`, data);
}

//查询CodeTerm
// export function getCodeTerm(data) {
//   return request.post(`/assigns/getCodeTerm`, data);
// }

//Code与调令时间通用校验
export function checkCodeAndAssignTerm(data) {
  return request.post(`/assigns/checkCodeAndAssignTerm`, data);
}

//判断是否是正式的Code
export function checkFormalCode(params) {
  return request.get(`/assigns/checkCodeByEngagementCode`, {params});
}

// RM上项目审批/确定
export function rmAudit(data) {
  return request.post(`/assigns/rmAudit`, data);
}

// RM下项目审批/确定/Release
export function rmReleaseAudit(data) {
  return request.post(`/assigns/rmReleaseAudit`, data);
}

// 查询申请审批记录
export function getAssignRecordList(params) {
  return request.get(`/assigns/records`, { params });
}

// RM修改Assignment时间
export function rmEditAssignmentTime(data) {
  return request.post(`/assigns/adjustPeriod`, data);
}

// PM批量申请修改人员上、下项目时间
export function pmBatchApply(data) {
  return request.post(`/batch/apply`, data);
}
// 批量时间重叠
export function batchOverlap(data) {
  return request.post(`/batch/overlap`, data);
}
// RM查看待审批项目列表
export function getAssignmentPendingList(data) {
  return request.post(`/batch/query`, data);
}
// RM查看待审批人员列表
export function getAssignmentPendingDetailList(data) {
  return request.post(`/batch/queryPending`, data);
}
// RM批量审批修改人员上、下项目时间
export function rmBatchAudit(data) {
  return request.post(`/batch/audit`, data);
}
// 查询项目的PM
export function rmBatchApplyPM(params) {
  return request.get(`/batch/findPM`, { params });
}

// rm批量修改时间
export function rmBatchModifyTime(data) {
  return request.post(`/batch/up`, data);
}

// PM按Code导出Assignment
export function downloadAssignmentFile(data) {
  return request.post("/assignmentInfo/pmExport", data, {
    responseType: "blob",
  });
}
// 获取上传的附件
export function getAnnexList(id) {
  return request.get(`/annexes/list?relationId=${id}`);
}

// 下载附件
export function downloadTheAnnex(params) {
  return request.get(`/annexes/singleDownload?businessType=${params.businessType}&fileId=${params.fileId}`,
      { responseType: "blob"});
}

// 删除附件
export function delTheAnnex(params) {
  return request.post(`/annexes/deleteFile`, {},{ params });
}
// 覆盖附件
export function replaceTheAnnex(params) {
  return request.post(`/annexes/replace`, params);
}
// 调令修改-获取调令详情
export function getCodeDataByEngagementCode(data) {
  return request.post(`/codeData/getCodeDataByEngagementCode`, data);
}
// 调令修改-code下拉列表
export function getCodeItems() {
  ///codeData/items
  return request.get(`/codeData/getCodeDataList`);
}

// 调令修改-调令时间校验
export function checkAssDate(data) {
  return request.post(`/codeData/checkCodeAndAssignmentDate`, data);
}
// 调令修改-保存
export function saveModifiedAssignment(data) {
  return request.post(`/codeData/updateEngagementCode`, data);
}
// 获取Desc 下拉列表
export function getDescItems() {
  return request.post(`/assignmentInfo/getAllDesc`);
}
// 批量通过EngagementCode查询所有主数据
export function getBatchCodeData(data) {
  return request.post(`/codeDataBatch/batchGetCodeDataByEmCode`, data);
}
// 批量通过EngagementCode查询所有主数据
export function bachSaveAssignments(data) {
  return request.post(`/codeDataBatch/batchSaveCodeDataByEmCode`, data);
}
//批量确定自动过滤
export function postCheckOverlap(data) {
  return request.post(`/batch/checkOverlap`, data);
}
// 查询release邮件发送记录
export function getqueryMailRecord(params) {
  return request.get(`/assignments/queryMailRecord`, { params });
}
// 校验Release邮件是否发送过
export function checkReleaseEmailSendStatus(data) {
  return request.post('/assigns/checkReleaseEmailSendStatus',data);
}

//获取PM team offering下拉框内容
export function getItemsPmOrg(params) {
  return request.get(`assignmentInfo/pmOrg/items`, { params });
}

//PM 通过org3.2查询org4.2
export function getOrgFourOneOne(params) {
  return request.get(`assignmentInfo/pmOrg/condition`, { params });
}

// 调令记录-查看变更记录
export function getAssignmentRecordList(params) {
  return request.get(`/histories/queryAssignmentHistory`, { params });
}

//未登录评价-PM改期-8小时
export function pmNoIsContinue(data) {
  return request.post(`/singleSignOn/isContinue`, data);
}
//未登录评价-PM改期-code
export function pmNoCheckCodeAndAssignTerm(data) {
  return request.post(`/singleSignOn/checkCodeAndAssignTerm`, data);
}
//未登录评价-PM改期-8小时
export function pmNoCheckReleaseEmailSendStatus(data) {
  return request.post(`/singleSignOn/checkReleaseEmailSendStatus`, data);
}

// 校验当前调令是否在待审批中
export function isRestartAssignment(data) {
  return request.post('/assignmentInfo/isRestartAssignment',data);
}

// 重启调令
export function rmRestartAssignment(data) {
  return request.post('/assignmentInfo/rmRestartAssignment',data);
}
// rmleader审批详情
export function reopenAssignmentInfo(id) {
  return request.get(`/assignmentInfo/reopenAssignmentInfo?id=${id}`);
}
// rmleader确认审批
export function approvalRestartAssignment(data) {
  return request.post('/assignmentInfo/approvalRestartAssignment',data);
}
