import request from "@/utils/http";

// 模板管理分页查询
export function welcomeLetterCustomTemplateList(data) {
    return request.post("/welcomeLetterCustomTemplate/page", data);
}
// 获取团队模板名字下拉
export function getTemplateByOffering(offering) {
    return request.get(`/welcomeLetterCustomTemplate/getTemplateByOffering?offering=${offering}`);
}
// 获取简历模板与入职指引文件及其他附件
export function getAllFiles(offering) {
    return request.get(`/welcomeLetterCustomTemplate/files?offering=${offering}`);
}
// 添加自定义模板
export function addDefineTemplate(data, params) {
    return request.post(`/welcomeLetterCustomTemplate/add`, data, {
        params,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
// 添加自定义模板
export function saveAsDefineTemplate(data, params) {
    return request.post(`/welcomeLetterCustomTemplate/saveAs`, data, {
        params,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
// 查询自定义模板
export function findDefineTemplate(templateId, offering) {
    return request.get(`/welcomeLetterCustomTemplate/see?templateId=${templateId}&offering=${offering}`);
}
// 删除自定义模板
export function deleteDefineTemplate(templateId) {
    return request.get(`/welcomeLetterCustomTemplate/delete?templateId=${templateId}`);
}
// 预览其他文件url
export function previewOthers(id) {
    return request.get(`/welcomeLetterCustomTemplate/Preview/${id}`);
}
// 修改自定义模板
export function editDefineTemplate(data) {
    return request.post(`/welcomeLetterCustomTemplate/update`, data);
}
// Template Name下拉数据获取
export function getTemplateOps() {
    return request.get(`/welcomeLetterCustomTemplate/templateName`);
}
// 上传附件
export function welcomeLetterTemplateUpload(data) {
    return request.post(`/welcomeLetterCustomTemplate/upload`, data);
}
// 删除其他附件
export function deleteOtherFiles(id) {
    return request.get(`/welcomeLetterCustomTemplate/deleteOtherFileById?id=${id}`);
}
// 上传文件
export function uploadGuide(data, params) {
    return request.post("/welcomeLetterCustomTemplate/uploadGuide", data, {
        params,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}
// 获取HRBP和RM对应的联系人邮箱
export function getHRBPAndRMData() {
    return request.get(`/welcomeLetterCustomTemplate/HRBPAndRMData`);
}