import request from "@/utils/http";

// 获取account主数据列表
export function getAccountMaintainList(data) {
  return request.post("/accountData/list", data);
}

// 根据ccountCode查询P&L,Sub_P&L
export function getPlAndSubPl(param) {
  return request.get(`/accountData/getPlAndSubPl?account=${param}`);
}

// 新增account主数据
export function addAccountMaintain(data) {
  // return request.post("/accountData/save", data);
  return request.post("/accountData/saveAccount", data);
}

// 修改account主数据
export function modifyAccountMaintain(data) {
  return request.post("/accountData/modify", data);
}

// 启用account主数据
export function activeAccountMaintain(data) {
  return request.post("/accountData/logicalEnable", data);
}

// 停用account主数据
export function deactiveAccountMaintain(data) {
  return request.post("/accountData/logicalDeletion", data);
}

//判断是否有在途调令
export function getIsAssignment(data) {
  return request.post("/accountData/isRelationAssignment", data);
}

//查询code的projectCloseDate
export function getProjectCloseDate(data) {
  return request.post("/accountData/getProjectCloseDate", data);
}

// 获取account查询参数列表
export function getAccountParamList(params) {
  return request.get("/accountData/account", { params });
}

// 获取account查询参数列表
export function getAccountHistory(params) {
  return request.get("/histories/query", { params });
}
// 新增account查询P&L,Sub_P&L数据
export function getPlAndSubPlList(params) {
  return request.get("accountData/getPlAndSubPlList", { params });
}

// 获取Account Domain Sub P&L P&L查询参数列表
export function getAccountPLItems(params) {
  return request.get("/accountData/items", { params });
}

// 获取Account Domain Sub P&L P&L对应关系
export function getAccountPLRelations(params) {
  return request.get("/accountData/relations", { params });
}

// 下载模板
export function downloadTemplate() {
  return request.get("/accountData/exportTemplate", { responseType: "blob" });
}

// 获取最近一次导入的数据的信息
export function getAccountStatusData(params) {
  return request.get("/accountData/import/status", { params });
}

//获取最近一次导入新增的数据的信息
export function getStatusAccountData(params) {
  return request.get("/accountData/import/statusAccount", { params });
}

// 查询当天日期
export function getToday(params) {
  return request.get("/accountData/today", { params });
}

// 导出
export function getAccountExport(data) {
  return request.post("/accountData/export", data, {
    responseType: "blob",
  });
}
// 导入
export function getAccountImport(data) {
  return request.post("/accountData/import", data);
}
// 获取详情
export function getAccountDetailData(params) {
  return request.get("/accountData/byId", { params });
}
// 新增编辑的pl和sub&pl联动关系
export function getPAndLSub(params) {
  return request.get("/staffDictionary/pls", { params });
}
// 获取勾选数据的详情-合并数据
export function getMergingData(data) {
  return request.post("/accountData/AccountDataById", data);
}
// 增加tbd code
export function addTbdCode(data) {
  return request.post("/accountData/saveTBDCode", data);
}
// 增加Domain
export function addDomainMaintain(data) {
  return request.post("/accountData/saveDomain", data);
}
// 获取captive和tier下拉列表内容
export function getCaptiveAndTier(params) {
  return request.get("/accountData/getCaptiveAndTier", { params });
}
// 合并
export function mergeDomainData(data) {
  return request.post("/accountData/mergeAccountDomain", data);
}
// 获取全部account的下拉列表
export function getAllAccountOpt() {
  return request.get("/accountData/allAccount");
}
// 获取相应domain的下拉列表
export function getTheDomainOpt(params) {
  return request.get("/accountData/DomainById", { params, loading: false });
}
// 获取captive下拉框 @todo
export function getCaptiveSelData(data) {
  return request.post("/accountData/todo", data);
}
// 获取tier下拉框 @todo
export function getTierSelData(data) {
  return request.post("/accountData/todo", data);
}
//获得domain
export function getDomain(data) {
  return request.post("/accountData/DomainByAccountId", data, );
}
