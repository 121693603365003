import request from "@/utils/http";

export function getStaffingSkillCategory(params) {
  return request.get("/staffDictionary/skillCategories", { params });
}

export function getSkillList(params) {
  return request.get("/staffDictionary/deptSkills", { params });
}

export function getStaffSkill(params) {
  return request.get("/staffskillstars", { params });
}

export function updateStaffSkill(data, params) {
  return request.post("/staffskillstars", data, { params });
}

//保存role
export function saveRole(params) {
  return request.post(`/staffskillstars/saveRole?id=${params.id}&position=${params.position}&positionId=${params.positionId}`);
}
//保存技能
export function saveSkill(data, params) {
  return request.post("/staffskillstars/insertStaffSkillStars", data, {params});
}
// export function getAllStaffSkillStar(query) {
//   return request.get("/staffskillstars/getStaffSkillStar", { params: query });
// }

// export function deleteStaffSkillStarById(id) {
//   return request.delete(`/staffskillstars/${id}`);
// }

// export function updateStaffSkillStarById(staffSkillStarCommand) {
//   return request.post("/staffskillstars", staffSkillStarCommand);
// }
