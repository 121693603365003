import request from "@/utils/http";

//获取角色授权列表
export function getPmEmpowerData(data) {
  return request.post("/staffPmEmpower/getPmEmpowerData", data);
}
//获取被授权人
export function getEmpoweredPerson() {
  return request.get("/staffPmEmpower/getEmpoweredPerson");
}
//获取engagementCode列表和调令人员列表
export function getcodeStaffItems() {
  return request.get("/assignmentInfo/codeStaffItems");
}
//获取engagementCode和调令人员关系
export function getcodeStaffRelations() {
  return request.get("/assignmentInfo/codeStaffRelations");
}
//获取engagementCode和调令人员关系(筛选项)
export function getPMCodeAndAssignment() {
  return request.get("/assignmentInfo/PMCodeAndAssignment");
}
//PM停用授权
export function stopEmpower(params) {
  return request.get("/staffPmEmpower/stopEmpower", { params });
}
//PM编辑授权
export function staffPmEmpowerEdit(data) {
  return request.post("/staffPmEmpower/edit", data);
}
//PM新增授权
export function staffPmEmpowerAdd(data) {
  return request.post("/staffPmEmpower/add", data);
}
//PM新增校验互斥人员
export function checkPMRoleMutex(params) {
  return request.get("/staffPmEmpower/checkPMRoleMutex", { params });
}
//pm批量停用授权
export function batchStopEmpower(params) {
  return request.post("/staffPmEmpower/batchStopEmpower", params );
}
