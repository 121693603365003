import request from "@/utils/http";

export function orgTemplate() {
  return request.get("/organizations/exportOrgTemplate", {
    responseType: "blob",
  });
}
export function exportOrgData(params) {
  return request.get(`/organizations/data?status=${params.status}&queryCondition=${params.condition}`,{ responseType: "blob" });
}
export function orgUpload(data,param) {
  return request.post(`/organizations/importOrg?isAll=${param}`, data);
}

export function getCostData(params) {
  return request.get("/costexcels", { params });
}
export function exportCostData() {
  return request.get("/costexcels/exportCosts", { responseType: "blob" });
}
export function costTemplate() {
  return request.get("/costexcels/exportModel", { responseType: "blob" });
}
export function costUpload(data,param) {
  return request.post(`/costexcels/importCosts?isAll=${param}`, data);
}
export function exportBpoData(data) {
  return request.post("/staffInfos/exportStaffInfo", data, { responseType: "blob" });
}
