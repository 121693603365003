import request from "@/utils/http";

// 查询需求信息(有无条件和模糊查询)
export function searchDemandByCondition(data) {
  return request.post(
    `/demandInfos?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}`,
    data
  );
}

// 查询顾问信息(有无条件和模糊查询)
export function queryConsultantByCondition(data) {
  return request.post(
    `/consultantMatchingInfos?pageIndex=${data.pageIndex}&pageSize=${data.pageSize}`,
    data,
  );
}

// 需求匹配重构之后的顾问列表查询条件
export function queryChangeCounsultCondition(data){
  return request.post(`/demandInfos/demandFistNew`, data);
}

// 顾问添加时候校验是否匹配
export function verifyConsultisInDemand(params) {
  return request.get(`/consultantMatchingInfos/checkUserPreMatchingInfoFirstStep`, { params, loading: false });
}

// 新增预匹配
export function newPreMatch(data) {
  return request.post(`/matchings`, data);
}

//查询预匹配
export function searchMatchByCondition(data) {
  return request.post(`/matches/permissions`, data);
}

//查询全部人员作为EM选择项
export function findAllStaffs() {
  return request.get(`/staffInfos/staffs`, { loading: false });
}

//查询IDCCODE选择项
export function getIdcCodes() {
  return request.get(`/staffWelcomeLetterTemplate/IDCCode`, { loading: false });
}
//查询全部人员作为PM选择项
export function findAllPM() {
  return request.get(`/staffInfos/PM`);
}

export function findMatchingPM() {
  return request.get(`/staffInfos/formatching`);
}

//查询最近3条assignment信息
export function getLatestThreeAssignments(params) {
  return request.get(`/assignments/latestThree`, { params, loading: false });
}

//查询最近3条preMatching信息
export function getLatestThreePreMatchings(params) {
  return request.get(`/matchings/latestThree`, { params, loading: false });
}

//查询最近3条preMatching信息
export function getLatestThreeTasks(params) {
  return request.get(`/works/latestThree`, { params, loading: false });
}

// 查询workExperience
export function getWorkExperience(params) {
  return request.get(`/assignmentInfo/assigmentReviews`, { params });
}

// 判断顾问是否有正在进行的匹配
// 校验一个需求同一个人只能匹配一次
export function isMatched(params) {
  return request.get(`/consultantMatchingInfos`, { params });
}

// 查询此需求是否已经被选定人员
export function isDemandMatched(params) {
  return request.get(`/matchings`, { params });
}

// 查询此需求是否已经被选定人员
export function isTADemandMatched(params) {
  return request.get(`/matchings/TA`, { params });
}

// 需求匹配新增校验，查询所选人员是否在这个需求里
export function isConsultInDemand(data) {
  return request.post(`/matches/verify`, data, { loading: false });
}

// 查询获取account下拉
export function getAccountIndustryLineOpts(params) {
  return request.get(`/accountData/items/accountIndustryLine`, { params });
}
// 查询code关系
export function getCodeRelations(params) {
  return request.get(`/codeData/relations`, { params });
}
// 获取account和industryLine关系
export function getAccountIndustryRelations(params) {
  return request.get(`/accountData/relations/accountIndustryLine`, { params });
}
// commit date校验
export function getCommitDateRange(data) {
  return request.post(`/codeData/projectCommitDateRange`, data);
}
// codeTerm校验
export function checkCodeTerm(data) {
  return request.post(`/codeData/checkCodeTerm`, data);
}
// 根据code获取相关信息
export function getCodeDataByCode(params) {
  return request.get(`/codeData/getCodeDataQueryByEngagementCode`, { params });
}
//新增免登录链接预匹配
export function createInputLink(data) {
  return request.post(`/singleSignOn/createInputLink`, data);
}
//驳回时弹窗判断
export function queryNoLoginLinkStatus(data) {
  return request.post(`/singleSignOn/queryNoLoginLinkStatus`, data);
}
//免登录驳回
export function rejectSendingNoLoginLinkStatus(data) {
  return request.post(`/singleSignOn/rejectSendingNoLoginLinkStatus`, data);
}
//点击免登录链接
export function clickInputLink(serialNo) {
  return request.post(`/singleSignOn/clickInputLink?serialNo=${serialNo}`);
}
//重新发送免登录链接邮件
export function reCreateInputLink(serialNo) {
  return request.post(`/singleSignOn/reCreateInputLink?serialNo=${serialNo}`);
}
//重新发送免登录链接邮件
export function queryAssignmentInfo(serialNo) {
  return request.post(`/singleSignOn/queryAssignmentInfo?serialNo=${serialNo}`);
}
//填写完调令后获取详细调令信息的序列号
export function getQueryLink(serialNo) {
  return request.get(`/singleSignOn/getQueryLink?serialNo=${serialNo}`);
}
//PM填写免登录链接-提交
export function confirmPm(serialNo, mode, data) {
  return request.post(`/singleSignOn/confirmPm?serialNo=${serialNo}&mode=${mode}`, data);
}

//PM填写免登录链接-根据code获取相关信息
export function getCodeDataQueryByEngagementCode(serialNo, params) {
  return request.get(`/singleSignOn/getCodeDataQueryByEngagementCode?serialNo=${serialNo}`, { params });
}
// PM填写免登录链接-commit date校验
export function projectCommitDateRange(serialNo, data) {
  return request.post(`/singleSignOn/projectCommitDateRange?serialNo=${serialNo}`, data);
}
//PM填写免登录链接--判断是否是正式的Code
export function checkCodeByEngagementCode(serialNo, params) {
  return request.get(`/singleSignOn/checkCodeByEngagementCode?serialNo=${serialNo}`, { params });
}
// PM填写免登录链接--codeTerm校验
export function pmCheckCodeTerm(serialNo, data) {
  return request.post(`/singleSignOn/checkCodeTerm?serialNo=${serialNo}`, data);
}
//PM填写免登录链接
export function getPMCity(serialNo, params) {
  return request.get(`/singleSignOn/city?serialNo=${serialNo}`, { params });
}
// 根据account查询code
export function relationsByAccount(data) {
  return request.post(`/codeData/relationsByAccount`,data );
}
// 分页查询code
export function getCodeDataOps(data) {
  return request.post(`/codeData/relationsByAccountAndCodeByMoHu`, data);
}

//PM 人员评价填写详情
export function getPMEvaluationInfo(serialNo, params) {
  return request.get(`/singleSignOn/evaluationInformation?serialNo=${serialNo}`, { params });
}

// PM 重新邮件申请
export function anotherGetPMEvaluationInfo(params) {
  return request.get(`singleSignOn/evaluation/consultantEvaluation`,{params});
}

// 调令到期重新发送邮件
export function anotherGetFinal(code) {
  return request.get(`singleSignOn/expirationInformation/assignmentExpirationEmail?code=${code}`);
}


//PM 改期填写详情
export function getPMExpirationInfo(serialNo, params) {
  return request.get(`/singleSignOn/expirationInformation?serialNo=${serialNo}`, { params });
}
// PM 改期确认
export function updateReleaseDate(data) {
  return request.post(`/singleSignOn/updateReleaseDate`, data);
}

// PM 点击改期按钮前获取状态
export function nonLoginGetChangeDateStatus(id) {
  return request.get(`/singleSignOn/queryApply?id=${id}`);
}
//新建匹配-第三步客户名称  部门层级
export function getRmPowerInfo(data) {
  return request.post(`/matches/getRmPowerInfo`, data);
}
//新建匹配-第三步 根据客户名称带出行业线
export function getAccountPl(account) {
  return request.get(`/matches/getAccountPl?account=${encodeURIComponent(account)}`);
}
//新建匹配-第三步 根据末级需求部门带出部门层级
export function getThreeTwoList(fourOneOne) {
  return request.get(`/matches/getThreeTwoList?fourOneOne=${fourOneOne}`);
}
//新建匹配-第三步自定义保存
export function customAdded(data) {
  return request.post(`/matches/customAdded`, data);
}

// 匹配信息PipeLine的搜索
export function matchingInfoListPipeLine(data) {
  return request.post(`/pipeline/getStaffPipeline`, data);
}

// 匹配信息自定义需求的搜索
export function matchingInfoListTwoTypesTable(data) {
  return request.post(`/demandInfos/matchingInfoList`, data);
}

// 自定义需求停用当前行
export function stopCurrentRow(data) {
  return request.post(`/demandInfos/isClose`, data);
}

// 自定义需求导出
export function customExport(data) {
  return request.post(`/demandInfos/matchingInfoExport`, data, { responseType: "blob" })
}

// 需求信息PipeLine的新建-邮箱下拉
export function returnEmai() {
  return request.get(`/pipeline/returnEmail`);
}
// 需求信息PipeLine的新建-下拉
export function returnEnum(data) {
  return request.post(`/pipeline/returnEnum`,data,{ loading: false });
}
// 需求信息PipeLine的修改-提交
export function updatePipLine(data) {
  return request.post(`/pipeline/updatePipLine`,data);
}
// 需求信息PipeLine的新建-提交
export function savePipeline(data) {
  return request.post(`/pipeline/savePipeline`,data);
}

// 自定义需求二次关闭
export function secondClose(data) {
  return request.post(`/demandInfos/compulsionClose`,data);
}
// 需求信息PipeLine导出
export function exportPipeline(data) {
  return request.post(`/pipeline/exportPipeline`,data);
}
// 需求信息PipeLine导入
export function importPipeline(data) {
  return request.post(`/pipeline/importPipeline`,data);
}
// 自定义需求校验
export function verifyStopRow(data){
  return request.post(`/demandInfos/isCheck`,data,{ loading: false });
}
// 查询新建匹配-pipeline列表
export function pipelineInfo(params) {
  return request.post(`/demandInfos/pipelineInfo`, params);
}
// 预匹配关联北森
export function pipeLineToDemand(data){
  return request.post(`/matches/sendPm`,data);
}