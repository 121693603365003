import request from "@/utils/http";

// 评价
export function emAppraises(data) {
    return request.post("/AssignmentReview/appraises", data);
}

// 批量授权
export function assignmentReviewEmpowerCommand(data) {
    return request.post("/AssignmentReview/empower", data);
}

//导出
export function getReviewExport(data) {
    return request.post("/AssignmentReview/export", data, {
        responseType: "blob",
    });
}

//查询列表数据
export function getAssignmentReviewList(data) {
    return request.post("/AssignmentReview/assignmentReviewList", data);
}

//被授权人
export function getAuthorizedPerson(data) {
    return request.post("/AssignmentReview/authorizedPerson", data);
}

//导入
export function reviewImport(data) {
    return request.post("/AssignmentReview/import", data);
}
