<template>
  <a-tooltip
      :overlayClassName="overlayClassName"
      :overlayStyle="overlayStyle"
      :autoAdjustOverflow="autoAdjustOverflow"
      :color="color"
      :defaultVisible="defaultVisible"
      :mouseEnterDelay="mouseEnterDelay"
      :mouseLeaveDelay="mouseLeaveDelay"
      :placement="placement"
      :trigger="trigger"
      :destroyTooltipOnHide="destroyTooltipOnHide"
  >
    <template #title>
      <span class="font-color">{{title}}</span>
    </template>
    <slot></slot>
  </a-tooltip>
</template>

<script>

import { defineComponent } from "vue";
export default defineComponent ({
  name: "Tooltip",
  components: {

  },
  props: {
    title: {
      type: String,
      default: ''
    },
    arrowPointAtCenter: {
      type: Boolean,
      default: false
    },
    autoAdjustOverflow: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: '#fff'
    },
    defaultVisible: {
      type: Boolean,
      default: false
    },
    mouseEnterDelay: {
      type: Number,
      default: 0.5
    },
    mouseLeaveDelay: {
      type: Number,
      default: 0.1
    },
    overlayClassName: {
      type: String,
      default: ''
    },
    overlayStyle: {
      type: Object,
      default: ()=>{}
    },
    placement: {
      type: String,
      default: 'top'
    },
    trigger: {
      type: String,
      default: 'hover'
    },
    destroyTooltipOnHide: {
      type: Boolean,
      default: false
    },
  },
  setup(){
    return {
    }
  }
});
</script>

<style lang="less" scoped>
.font-color{
  color: #4d4d4d;
}
</style>
