import request from "@/utils/http";

export function getNoticeNum() {
  return request.get("/staffNotices/noticeNumbers", { loading: false });
}
export function getNoticeInfo(noticeStatus, pageIndex, pageSize) {
  return request.get(
    `/staffNotices?noticeStatus=${noticeStatus}&pageIndex=${pageIndex}&pageSize=${pageSize}`,
    { loading: false }
  );
}
export function getNoticeList(params) {
  return request.get("/staffNotices/noticeTypes", params);
}
export function delectAllNotice(params) {
  return request.post("/staffNotices/deleteAllNotification", params);
}
export function readNoticeListDone(params) {
  return request.post("/staffNotices/allRead", params);
}
export function readSingleNoticeDone(params) {
  return request.post("/staffNotices/read", params);
}
export function delectNotice(params) {
  return request.post("/staffNotices/deleteNotification", params);
}

export function getNoticeListByStatus(params) {
  return request.get("/staffNotices/status", params);
}

export function readAll(params) {
  return request.post("/staffNotices/readAll", params);
}
// 获取消息列表
export function getNoticesList(params) {
  return request.post("/staffNotices/searchNotices", params);
}
export function getMenusRelation(params) {
  return request.get("/staffDictionary/getMenu", params);
}
export function getMenusFromAllData(params) {
  return request.get("/staffNotices/getNowMenu", params);
}

//折叠消息全部已读
export function postReadParentNotice(params) {
  return request.post("/staffNotices/readParentNotice", params);
}