import { getCurrentInstance, nextTick } from "vue";
import moment from "moment";
export const useGlobalPropertyHook = () => {
  return getCurrentInstance().appContext.config.globalProperties;
};


//  传入[
//    {startDate: '2023-12-18', endDate: '2023-12-27'},
//    {startDate: '2023-12-20', endDate: '2023-12-25'},
//    {startDate: '2024-12-20', endDate: '2024-12-25'}
// ]
// 返回两个数组，第一个是完全和其他时间无交集的，第二个是时间之间存在交集的

export const findNonOverlappingTimeRanges = (timeRanges) => {
  let nonOverlappingRanges = [];
  let overlappingRanges = [];

  for (let i = 0; i < timeRanges.length; i++) {
    let currentRange = timeRanges[i];
    let isOverlapping = false;

    for (let j = 0; j < timeRanges.length; j++) {
      if (i !== j) {
        let compareRange = timeRanges[j];

        let currentStart = new Date(currentRange.startDate);
        let currentEnd = new Date(currentRange.endDate);
        let compareStart = new Date(compareRange.startDate);
        let compareEnd = new Date(compareRange.endDate);

        // Check if start or end date falls within another range
        if ((currentStart >= compareStart && currentStart <= compareEnd) ||
          (currentEnd >= compareStart && currentEnd <= compareEnd)) {
          isOverlapping = true;
          break;
        }
      }
    }

    if (!isOverlapping) {
      nonOverlappingRanges.push(currentRange);
    } else {
      overlappingRanges.push(currentRange);
    }
  }

  return [nonOverlappingRanges, overlappingRanges]
}

// 根据传入的时间的startDate进行排序, 并且根据startDate和endDate算出之间day的差距
export const sortByStartDate = (array) => {
  array.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  array.forEach((item) => {
    const start = new Date(item.startDate);
    const end = new Date(item.endDate);
    const timeDiff = Math.abs(end - start);
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    item.timeDifference = diffDays;
    // item.index = startIndex + index;
  });
  return array;
}

// 根据传入的时间，如果有displayDate和displayEndDate的话计算这两个距离，不然则根据startDate和endDate算出之间day的差距
export const sortByDisplayDate = (array) => {
  array.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
  array.forEach((item) => {
    let startDate = item.displayStartDate != '' ? item.displayStartDate : item.startDate
    let endDate = item.displayEndDate != '' ? item.displayEndDate : item.endDate
    const start = new Date(startDate);
    const end = new Date(endDate);
    const timeDiff = Math.abs(end - start);
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24))===0? 1 : Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    //FCST Extension Assumption Date与endDate距离
    if(item?.extensionAssumptionDate) {
      const fcstDate = new Date(item.extensionAssumptionDate);
      const fcstDays = moment(fcstDate).diff(endDate, 'day');
      item.fcstTimeDifference = fcstDays;
    }
    item.timeDifference = diffDays;
  });
  return array;
}

// 检查是否早于或者晚于主调令，并且修改
export const checkAndReplaceDates = (data, basicStart, basicEnd) => {
  const basicStartTimestamp = new Date(basicStart).getTime();
  const basicEndTimestamp = new Date(basicEnd).getTime();

  for (let item of data) {
    const startDateTimestamp = new Date(item.startDate).getTime();
    const endDateTimestamp = new Date(item.endDate).getTime();
    if (startDateTimestamp < basicStartTimestamp) {
      item.startDate = basicStart;
    }
    if (endDateTimestamp > basicEndTimestamp) {
      item.endDate = basicEnd;
    }
  }
  return data;
}
// 检查是否早于或者晚于主调令，但是不修改只添加
export const checkAndAddDates = (data, basicStart, basicEnd, basicFcst) => {
  const basicStartTimestamp = new Date(basicStart).getTime();
  const basicEndTimestamp = new Date(basicEnd).getTime();
  const basicFcstTimestamp = new Date(basicFcst).getTime();

  for (let item of data) {
    const startDateTimestamp = new Date(item.startDate).getTime();
    const endDateTimestamp = new Date(item.endDate).getTime();

    if (startDateTimestamp < basicStartTimestamp) {
      item.displayStartDate = basicStart;
    } else {
      item.displayStartDate = ''
    }
    if(basicFcstTimestamp && endDateTimestamp > basicFcstTimestamp) {
      item.displayEndDate = basicFcst;
    } else if (!basicFcstTimestamp && endDateTimestamp > basicEndTimestamp) {
      item.displayEndDate = basicEnd;
    } else {
      item.displayEndDate = ''
    }
  }
  return data;
}

// 计算传入的数组的每一项距离主调令开始时间的天数差，默认主调令的时间一定会早于或者等于每一项的
// 的startDate，计算时间差之后知道每一项的偏移量
export const calculateBufferStartDate = (arr, date) => {
  const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
  arr.forEach((item) => {
    const bufferStartDate = new Date(item.startDate);
    const targetDate = new Date(date);
    const diffDays = Math.round(Math.abs(bufferStartDate - targetDate) / oneDay);
    item.bufferStartDate = diffDays;
  });
  return arr;
}

// 存在displayDate的时候计算偏移
export const calculateBufferDisplayDate = (arr, date) => {
  const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
  arr.forEach((item) => {
    let startDate = item.displayStartDate !== '' ? item.displayStartDate : item.startDate
    const bufferStartDate = new Date(startDate);
    const targetDate = new Date(date);
    const diffDays = Math.round(Math.abs(bufferStartDate - targetDate) / oneDay);
    item.bufferStartDate = diffDays;
  });
  return arr;
}


// 跳转滚动条到底部
export const scrollToBottom = (elementId) => {
  nextTick(() => {
    let element = document.getElementById(elementId);
    element.scrollTop = element.scrollHeight;
  })
}

// 检查重复日期 startDate第一次出现 startRepeat:true endDate第一次出现时 endRepeat:true , 反之为false
export const verifyFirstDate = (arr) => {
  let startObj = {};
  let endObj = {};

  // 获取所有的 startDate 和 endDate 并去重
  let startDates = arr.map(item => item.startDate);
  let endDates = arr.map(item => item.endDate);
  startDates = [...new Set(startDates)];
  endDates = [...new Set(endDates)];

  // 初始化 startObj 和 endObj
  startDates.forEach(date => startObj[date] = 0);
  endDates.forEach(date => endObj[date] = 0);

  // 遍历数组，给每个 item 设置标识
  arr.forEach(item => {
    if (startObj[item.startDate] === 0) {
      item.startRepeat = true;
      startObj[item.startDate] = 1;
    } else {
      item.startRepeat = false;
    }
    if (endObj[item.endDate] === 0) {

      item.endRepeat = true;
      endObj[item.endDate] = 1;
    } else {
      item.endRepeat = false;
    }
  });

  return arr;
}

// 如果传入的数组里开始时间晚于结束时间则去掉，结束时间早于开始时间页去掉
export const filterObjects = (arr, startDate, endDate,fcstDate) => {
  // 基础开始时间 和 基础结束时间
  const startTimestamp = new Date(startDate).getTime();
  const endTimestamp = new Date(endDate).getTime();
  const fcstTimestamp = new Date(fcstDate).getTime();

  return arr.filter(item => {
    let flag = true
    const itemStartTimestamp = new Date(item.startDate).getTime();
    const itemEndTimestamp = new Date(item.endDate).getTime();

    if (fcstTimestamp && (itemEndTimestamp < startTimestamp || itemStartTimestamp > fcstTimestamp)) {
      flag = false
    } else if (!fcstTimestamp && (itemEndTimestamp < startTimestamp || itemStartTimestamp > endTimestamp)) {
      flag = false
    }

    return flag;
  });
}
