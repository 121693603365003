import request from "@/utils/http";

//健康度看板登陆人组织筛选
export function getOrgSelect() {
  // return request.get("/health/board/orgSelect");
  return request.get("/boards/orgSelect");

}

//健康度看板登陆人信息
export function getPerson() {
  return request.get("/health/board/person");
}

//健康度看板所属人员（LM Pool）数量
export function getBelongs(orgs = "") {
  // return request.get(`/health/board/belongs?orgs=${orgs}`);
  return request.get(`/boards/belongs?orgs=${orgs}`);
}

//健康度看板领导力健康红绿灯信息
export function getLights(orgs = "") {
  // return request.get(`/health/board/lights?orgs=${orgs}`);
  return request.get(`/boards/lights?orgs=${orgs}`);
}

//健康度看板雷达信息
export function getRadar(orgs) {
  return request.get(`/health/board/radar?orgs=${orgs}`, { loading: false });
}

//健康度看板层级领导力健康度信息
export function getHierarchy(orgs = "") {
  return request.get(`/health/board/hierarchy?orgs=${orgs}`);
}
export function getNewHierarchy(orgs = "") {
  return request.get(`/boards/hierarchy/detail?orgs=${orgs}`,);
}
//健康度看板领导力健康度状态评分
export function getScores(orgs = "") {
  // return request.get(`/health/board/collect?orgs=${orgs}`);
  return request.get(`/boards/collect?orgs=${orgs}`);
}


//健康度看板组织架构
export function getOrg(params = {}) {
  return request.get("/health/board/org", { params });
}

//健康度筛选项
export function getHealthSelect() {
  return request.get("/health/detail/screen/health", { loading: false });
}

//高离职风险筛选项
export function getAttritionRiskSelect() {
  return request.get("/health/detail/screen/attritionRisk", { loading: false });
}

//组织层级筛选项
// export function getHierarchySelect() {
//   return request.get("/health/detail/screen/hierarchy", { loading: false });
// }

//组织层级筛选项-健康度明细
export function getHierarchySelect(params) {
  return request.get("/boards/combobox/hierarchies", {params});
}

//组织层级筛选项-储备管理
export function getHierarchyFromPool(params) {
  return request.get("/reserve/combobox/hierarchies", {params});
}

//识别动机筛选项
export function getMotivationSelect() {
  return request.get("/health/detail/screen/motivation", { loading: false });
}

//组织筛选项
export function getOrgsSelect(orgs = "") {
  return request.get(`/health/detail/screen/org?orgs=${orgs}`, { loading: false });
}

//onShore筛选项
export function getOnShoreSelect() {
  return request.get("/health/detail/screen/onShore", { loading: false });
}
export function getLeadershipDetailData(data = {}) {
  return request.post("/details/list", data);
}
//领导力明细查询
export function getReserveDetailData(data = {}) {
  // return request.post("/health/board/skip", data);
  return request.post("/reserve/skip", data);
}

//下载模板
export function exportLeadershipDetailTemplate(orgs = "") {
  return request.get(`/details/download?orgs=${orgs}`, {
    responseType: "blob",
  });
}

//领导力健康度信息导入
export function importLeadershipDetailData(data) {
  return request.post("/details/import", data);
}

//领导力个人明细记录导出
export function exportLeadershipDetailData(data) {
  // `/health/detail/download`
  return request.post(`/details/downloadDetail`, data, {
    responseType: "blob",
  });
}

//储备管理导出
export function exportReserveDetailData(data) {
  return request.post(`/reserve/download`, data, {
    responseType: "blob",
  });
}

//领导力个人明细记录更新
export function updateLeadershipDetailData(data) {
  return request.post(data.isPoolTab ? "/details/update" : "/reserve/update" , data);
}

export function getLeadershipDetailHistory(params) {
  // return request.get("/health/detail/history", { params });
  return params.isPoolTab ?
      //领导力个人明细历史记录
      request.post("/details/history", params) :
      //储备管理历史记录
      request.get("/reserve/history", {params});
}

//领导力个人明细历史记录导出
export function exportLeadershipDetailHistory(params) {
  return request.post(
      // "/health/detail/history/download",
      params.isPool === '0' ?
          "/details/history/download" : "/reserve/history/download",
      params,
      { params, responseType: "blob" }
  );
}

//储备管理新增保存
export function saveLeadershipDetailData(data) {
  // return request.post("/health/reserve/save", data);
  return request.post("/reserve/save", data);
}

//储备管理删除
export function deleteLeadershipDetailData(id) {
  // return request.post(`/health/reserve/delete?id=${id}`);
  return request.post(`/reserve/delete?id=${id}`);
}

//储备管理查询用户
export function searchLeadershipUser(params) {
  // return request.get("/health/reserve/user", { params });
  return request.get("/reserve/user", { params });
}

//储备管理查询用户
export function checkLeadershipMgmt() {
  return request.get("/health/check");
}

//确定名下是否有人
export function handleCheckPeople(userId) {
  // return request.get(`/health/isPersonUnderLM?userId=${userId}`);
  return request.get(`/details/isPersonUnderLM?userId=${userId}`);
}

//健康度明细删除名下无人的lm
export function deleteLmData(userId) {
  // return request.get(`/health/delLMRole?userId=${userId}`);
  return request.get(`/details/delLMRole?userId=${userId}`);
}
//获取当前年度季度
export function getCurrentQuarter() {
  return request.get(`/details/getQuarter`);
}
//健康度明细雷达图数据
export function getDetailRadarData(data={}) {
  return request.post(`/details/radar`,data);
}
//健康度明细chart图数据
export function getDetailChartData(data={}) {
  return request.post(`/details/chart`,data);
}
//获取历史记录中人员信息
export function getHistoryUserInfo(userId) {
  return request.get(`/users/userInfo/${userId}`);
}