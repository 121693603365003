import request from "@/utils/http";

export function infoExport() {
  return request.get("/exportInfos", { responseType: "blob" });
}

// assignment和评价信息
export function assignmentExportList(data) {
  return request.post("/exportInfos/assignments", data);
}

export function assignmentExport(data) {
  return request.post("/exportInfos/assignments/ex", data, {
    // responseType: "blob",
  });
}

// task信息导出
export function workTaskExportList(data) {
  return request.post("/exportInfos/workTask", data);
}
export function workTaskExport(data) {
  return request.post("/exportInfos/workTask/ex", data, {
    responseType: "blob",
  });
}

//顾问证书信息
export function certificationExportList(data) {
  return request.post("/exportInfos/certifications", data);
}

export function certificationExport(data) {
  return request.post("/exportInfos/certifications/ex", data, {
    responseType: "blob",
  });
}

//顾问简历信息
export function cvExportList(data) {
  return request.post("/exportInfos/cvs", data);
}

export function cvExport(data) {
  return request.post("/exportInfos/cvs/ex", data, {
    responseType: "blob",
  });
}

//预匹配信息
export function matchExportList(data) {
  return request.post("/exportInfos/preMatchings", data);
}

export function preMatchingExport(data) {
  return request.post("/exportInfos/preMatchings/ex", data, {
    responseType: "blob",
  });
}

//顾问技能信息
export function skillExportList(data) {
  return request.post("/exportInfos/skills", data);
}

export function skillExport(data) {
  return request.post("/exportInfos/skills/ex", data, { responseType: "blob" });
}

export function getSkillsAndSkillGroup(data) {
  return request.post("/exportInfos/getSkillsAndSkillGroup", data);
}

//BenchTask信息
export function taskExportList(data) {
  return request.post("/exportInfos/tasks", data);
}

export function taskExport(data) {
  return request.post("/exportInfos/tasks/ex", data, { responseType: "blob" });
}

//roleReview信息
export function roleReviewExportList(data) {
  return request.post("/exportInfos/roleReviewer", data);
}

export function roleReviewExport(data) {
  return request.post("/exportInfos/roleReviewer/ex", data, { responseType: "blob" });
}
