import { createStore } from "vuex";
import app from "./modules/app";
import page from "./modules/page";

export default createStore({
  modules: {
    app,
    page,
  },
});
