import store from "@/store";
import router from "@/router";

router.beforeEach((to, from, next) => {
  if (from && from.name === "Login") {
    store.commit("SET_IS_FIRST", true);
  } else {
    store.commit("SET_IS_FIRST", false);
  }

  // 判断此跳转路由是否存在，不存在跳转到404页面
  if (to.matched.length === 0) {
    let role = store.state.app.defaultRole? store.state.app.defaultRole : localStorage.getItem("switchRoleName")
    if(role === 'RM') {
      next("/dashboard");
    } else {
      next("/home");
    }
  }
  if (to.name == "Login" || to.name == "401" || to.name == "404" || to.name === 'singleSignOn' || to.name === 'evaluation' || to.name === 'expirationInformation') {
    next();
  }else {
    if(!store.state.app.token && localStorage.getItem("accessToken")) {
      store.commit("SET_TOKEN", localStorage.getItem("accessToken"));
      store.commit("SET_USERINFO", JSON.parse(localStorage.getItem("userInfo")));
      store.commit("SET_PERMS", localStorage.getItem("accessPerms").split(','));
      store.commit("SET_MENUS", localStorage.getItem("accessMenus").split(','));
      store.commit("SET_PAGES", localStorage.getItem("accessPages").split(','));
      if(JSON.parse(localStorage.getItem("menusList"))) {
        store.commit("SET_MENUSLIST",JSON.parse(localStorage.getItem("menusList"))?.menusList);
      } else {
        store.dispatch("generateMenus");
      }
    }
    let permissions = store.state.app.pages;
    const isHasPermission = permissions && permissions.length > 0;
    if (store.state.app.token == "") {
      next("/login");
    } else {
      if (!isHasPermission) {
        store
          .dispatch("generatePermission")
          .then((res) => {
            permissions = res.router;
            if (
              (permissions && permissions.find((item) => item === to.path)) ||
              to.path === "/home" ||
              to.path === "/userInfo"
            ) {
              next();
            } else {
              //message.error("当前用户无权限访问");
              next("/404");
            }
          })
          .catch(() => {
            store.commit("RESET_TOKEN");
            // 为了邮件跳转
            if(to.path) localStorage.setItem("originPath", to.fullPath)
            next("/login");
          });
      } else {
        if (
          permissions.find((item) => item === to.path) ||
          to.path === "/home" ||
          to.path === "/userInfo" ||
          to.path === "/dashboard"
        ) {
          next();
        } else {
          //message.error("当前用户无权限访问");
          next("/404");
        }
      }
    }
    // const hasRoles = store.state.app.perms && store.state.app.perms.length > 0;
    // if (store.state.token == "") {
    //   next("/login");
    // } else if (hasRoles) {
    //   next();
    // } else {
    //   store
    //     .dispatch("generatePermission")
    //     .then(() => {
    //       next();
    //     })
    //     .catch(() => {
    //       store.commit("RESET_TOKEN");
    //       next("/login");
    //     });
    // }
  }
});
export default router;
