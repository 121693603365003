import axios from "axios";
// 请求拦截器
import store from "@/store";
import router from "@/router";
import { message } from "ant-design-vue";
import { ElMessageBox } from 'element-plus'

const request = axios.create({
  headers: {
    "content-type": "application/json;charset=UTF-8",
    "Cache-Control": "no-cache",
    "Pragma": "no-cache",
  },
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 1000 * 60 * 3,
  withCredentials: true,
});

request.interceptors.request.use((config) => {
  if (config?.loading !== false) {
    store.commit("ADD_PENDING_REQUEST_COUNT");
  }
  config.headers["Authorization"] = store.state.app.token;
  if (config.method.toLowerCase() === "post") {
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data; charset=UTF-8";
    }
  }
  config.params = Object.assign({ t: new Date().getTime() }, config.params)
  return config;
});
// 响应拦截器
request.interceptors.response.use(
  (response) => {
    if (response.config?.loading !== false) {
      store.commit("SUBTRACT_PENDING_REQUEST_COUNT");
    }
    if (
      response.status === 200 &&
      (response.data instanceof Blob || response.data.code === 200)
    ) {
      // 文件名
      if (response.data instanceof Blob) {
        let matches = "";
        if (response.headers["content-disposition"]) {
          matches =
            response.headers["content-disposition"].match(/filename=([^;]+)/);
        }
        if (response.data.type === "application/json") {
          return response.data.text().then((res) => {
            let data = {};
            try {
              data = JSON.parse(res);
            } catch (error) {
              return Promise.reject(error);
            }
            if (data.code !== 200) {
              if (data.msg) message.error(data.msg);
              return Promise.reject(data);
            }
          });
        } else {
          const message = response.headers["data"];
          const successDownLoadNum = response.headers["number"];
          const data = {
            file: response.data,
            filename:
              matches && matches.length === 2
                ? decodeURIComponent(matches[1])
                : "download",
            otherMsg: message,
            number: successDownLoadNum,
          };
          response.data = data;
        }
      }

      return Promise.resolve(response.data);
    } else {
      if(response.data.msg != '该需求已匹配完成，请重新选择需求！'){
        message.error(response.data.msg);
      }
      return Promise.reject(response.data);
    }
  },
  (error) => {
    if (error.config?.loading !== false) {
      store.commit("SUBTRACT_PENDING_REQUEST_COUNT");
    }
    if (error?.message === "Network Error") {
      message.error("网络错误");
    } else if (error?.message.includes("timeout")) {
      message.error("请求超时");
    } else {
      if (error?.response) {
        const originPath = localStorage.getItem("originPath")
        switch (error.response.status) {
          case 400:
            new Error("请求错误");
            break;
          case 401:
            // 后台接口没有权限也会返回status是401，但是code是403情况
            if(error.response.data.code === 403) {
              ElMessageBox.confirm(
                '该功能您没有操作权限，请联系管理员反馈情况。',
                {
                  confirmButtonText: '确定',
                  type: 'error',
                  showCancelButton: false,
                  showClose: false,
                }
              )
              break;
            }
            // store.commit("RESET_TOKEN");
            // 为了邮件跳转
            if(originPath) localStorage.setItem("originPath", originPath)
            router.push("/401");
            break;
          case 500:
            message.error("请求错误");
            break;
        }
      }
      return Promise.reject(error.response);
    }
    return Promise.reject(error);
  }
);

export default request;
